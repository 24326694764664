import { navigate } from "@reach/router";

import * as encryptionApi from "../apis/encryption.api";
import errorTypes, { AUTHENTICATION_ERRORS } from "../constants/errors";
import {
  removeSpecialCharacters,
  formatPhone,
  addBrazilPhonePrefix
} from "../helpers";
import * as Utils from "../helpers";
import {
  getUserFromAccessToken,
  getAuthorizationHeader,
  getClientToken
} from "../helpers/user.helper";
import {
  User as UserFactory,
  UserAutocomplete as UserAutocompleteFactory
} from "../parsers/user.parser";
import { api } from "~/apps/shared/services/api";

export function contextOfChoice(userToken) {
  return api
    .request({
      url: `/booking/users/${userToken}/context-of-choice`,
      method: "POST",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function login(email, password) {
  return encryptionApi
    .getGeneralEncryptionData()
    .then(({ encryptionToken, publicKey }) => {
      return api
        .request({
          url: "/auth/basic",
          method: "POST",
          withCredentials: true,
          data: {
            email,
            password: Utils.encryptPassword(
              password,
              encryptionToken,
              publicKey
            )
          }
        })
        .then(({ data }) => {
          const { access_token } = data.data;
          localStorage.setItem("access_token", access_token);

          return getUserFromAccessToken(access_token);
        });
    })
    .catch(handleError);
}

export function createUser(userValues) {
  return api
    .request({
      url: "/booking/users",
      method: "POST",
      headers: getAuthorizationHeader(),
      data: buildUserRequestData(userValues)
    })
    .then(response => {
      const user = UserFactory(response.data);
      return user;
    });
}

export function editUser(userValues, userToken) {
  return api
    .request({
      url: `/booking/users/${userToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: buildUserRequestData(userValues, true)
    })
    .then(response => {
      const user = UserFactory(response.data);
      return user;
    });
}

export function partialEditUse(fields, userToken) {
  return api
    .request({
      url: `/booking/users/${userToken}`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: fields
    })
    .then(response => {
      const user = UserFactory(response.data);
      return user;
    });
}

export function getShareCode(userToken) {
  return api
    .request({
      url: `/booking/users/${userToken}/share-code`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function generateShareCode(userToken) {
  return api
    .request({
      url: `/booking/users/${userToken}/share-code`,
      method: "POST",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function addCountInShareCodeInfo(userToken) {
  return api
    .request({
      url: `/booking/users/${userToken}/add-count-in-share-code-info`,
      method: "POST",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function editUserProfile(data, userToken) {
  return api
    .request({
      url: `/booking/user/${userToken}/profile`,
      method: "PUT",
      headers: getAuthorizationHeader(),
      data
    })
    .then(response => UserFactory(response.data));
}

export function getUserRequest(userToken) {
  return api
    .request({
      url: `/booking/user/${userToken}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(({ data }) => {
      const user = UserFactory(data);

      if (!user.fullName) {
        user.fullName = `${user.firstName} ${user.lastName}`;
      }

      return user;
    });
}

export function getUser(userToken) {
  return getUserRequest(userToken).catch(handleError);
}

export function getUsersByName(name) {
  return api
    .request({
      url: `/booking/user/search/${name}`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data.map(userDto => UserAutocompleteFactory(userDto));
    })
    .catch(handleError);
}

export function deleteUser(user) {
  return api
    .request({
      url: `/booking/user/${user.userToken}`,
      method: "DELETE",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    })
    .catch(handleError);
}

export function logout() {
  return api
    .request({
      url: "/auth/logout",
      method: "POST",
      headers: getAuthorizationHeader()
    })
    .then(() => {
      localStorage.removeItem("access_token");
      navigate("/login");
    })
    .catch(err => console.log(err));
}

function buildUserRequestData(userValues, update = false) {
  const requestData = {};
  const { manualManagement, ...capabilities } = userValues.capabilities;

  requestData.first_name = userValues.firstName.trim();
  requestData.last_name = userValues.lastName.trim();
  requestData.email = userValues.email.trim();
  requestData.role = userValues.role;
  requestData.capabilities = capabilities;

  requestData.booking_target_tokens = capabilities.search
    ? userValues.bookerTargets.map(target => target.userToken)
    : [];

  if (!update) {
    requestData.client_token = getClientToken();
  }

  if (userValues.document) {
    requestData.document = removeSpecialCharacters(userValues.document);
  }

  if (userValues.hasOwnProperty("billingProfile")) {
    requestData.billing_profile_token = userValues.billingProfile;
  }

  if (userValues.hasOwnProperty("phone")) {
    requestData.phone = userValues.phone
      ? addBrazilPhonePrefix(formatPhone(userValues.phone))
      : userValues.phone;
  }

  if (userValues.hasOwnProperty("sendSms")) {
    requestData.send_sms = userValues.sendSms;
  }

  if (userValues.hasOwnProperty("sendInvite")) {
    requestData.send_invite = userValues.sendInvite;
  }

  if (userValues.hasOwnProperty("guest")) {
    requestData.guest = userValues.guest;
  }

  return requestData;
}

export function handleError(error) {
  if (error.response) {
    const { data, status } = error.response;
    const errorData = data?.data;

    if (status === 403) {
      if (data.message === errorTypes.ERROR_INVALID_CREDENTIALS) {
        throw { title: "title", description: "Usuário ou senha inválidos" };
      }

      if (data.message === AUTHENTICATION_ERRORS.AUTH_METHOD_NOT_ENABLED) {
        throw {
          title: "Erro de autenticação",
          description:
            "Cliente não possuí esse metódo de autenticação habilitado"
        };
      }

      if (data.type === errorTypes.EMAIL_ALREADY_EXIST) {
        throw {
          title: "title",
          description: "Já existe um usuário cadastrado com este email"
        };
      }

      if (!!errorData && "login_attempts_left" in errorData) {
        if (errorData.login_attempts_left > 0) {
          throw {
            title: "title",
            description: `Email e/ou senha inválidos. Você tem mais ${errorData.login_attempts_left} tentativas de login antes de bloquear sua conta.`
          };
        }

        throw {
          title: "title",
          description:
            "Sua conta foi bloqueada por excesso de tentativas erradas de login. Altere sua senha para desbloquear a conta."
        };
      }
    }

    if (status === 400) {
      if (data.type === errorTypes.ERROR_INVALID_INPUT) {
        throw { title: "title", description: "Preenchimento inválido" };
      }

      if (data.linked_as_approver) {
        throw {
          title: "title",
          description:
            "O usuário está vinculado a um ou mais Processos de Aprovação. Remova-o como aprovador antes de deletar"
        };
      }

      if (data.linked_as_approval_target) {
        throw {
          title: "title",
          description:
            "O usuário está vinculado a um ou mais Processos de Aprovação. Remova-o como alvo antes de deletar"
        };
      }

      if (data.linked_as_expense_approval_target) {
        throw {
          title: "title",
          description:
            "O usuário está vinculado a um ou mais Processos de Aprovação de Despesas. Remova-o como alvo antes de deletar"
        };
      }
    }

    throw {
      title: "title",
      description: "Usuário ou senha inválidos"
    };
  }
  // else if (error.request) {
  //   // The request was made but no response was received
  //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //   // http.ClientRequest in node.js
  // } else {
  //   // Something happened in setting up the request that triggered an Error
  // }
  throw { title: "title", description: "Erro inesperado" };
}

export function reenviteUser(userToken) {
  return api
    .request({
      url: `/booking/user/${userToken}/reinvite`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function getUserAcceptedTerms(userToken) {
  return api
    .request({
      url: `/booking/user/${userToken}/terms`,
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function updateUserAcceptTerms(userToken) {
  return api
    .request({
      url: `/booking/user/${userToken}/terms`,
      method: "PUT",
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function unarchiveUser(userToken) {
  return api.request({
    url: `/booking/user/${userToken}/unarchive`,
    method: "POST",
    headers: getAuthorizationHeader()
  });
}

export function getTravelsCount(loggedUserToken, filters) {
  const { travelerToken, search, pendingFilters } = filters;

  const parsedPendingFilters = pendingFilters.join("&");

  const params = {
    search,
    traveler: travelerToken
  };

  return api
    .request({
      url: `/booking/users/${loggedUserToken}/travels-count?${parsedPendingFilters}`,
      method: "GET",
      params,
      headers: getAuthorizationHeader()
    })
    .then(response => response.data);
}

export function ssoLogin(email) {
  return api
    .request({
      url: "/auth/oidc",
      method: "POST",
      data: { email },
      withCredentials: true
    })
    .then(({ data }) => data.data);
}

export function ssoLoginUniq(ssoConfigToken) {
  return api
    .request({
      url: `/auth/oidc/${ssoConfigToken}/uniq`,
      method: "GET"
    })
    .then(({ data }) => data.data);
}

export const userService = {
  login,
  logout,
  createUser,
  editUser,
  partialEditUse,
  getShareCode,
  generateShareCode,
  addCountInShareCodeInfo,
  getUser,
  getUsersByName,
  getUserRequest,
  deleteUser,
  reenviteUser,
  unarchiveUser,
  getTravelsCount,
  contextOfChoice
};

export default userService;
