import { DOCUMENT_TYPES } from "~/apps/shared/constants";
import { CARD_ISSUERS } from "~/apps/shared/constants/credit-card.constant";
import { GenderType } from "~/apps/shared/constants/enums";
import { validateCPF, validateRG } from "~/apps/shared/helpers/document.helper";
import { formatPhone } from "~/apps/shared/utils/format-phone";
import { removeBlankSpaces } from "~/apps/shared/utils/remove-blank-spaces";
import * as yup from "yup";

export const capabilities = () => {
  return yup.object().shape({
    analytics: yup.bool(),
    approvalAnyTimeForOthers: yup.bool(),
    approvals: yup.bool(),
    basic: yup.bool(),
    batchActions: yup.bool(),
    configurations: yup.bool(),
    eventOrganizer: yup.bool(),
    expenseForOthers: yup.bool(),
    expenses: yup.bool(),
    externalCredentials: yup.bool(),
    financial: yup.bool(),
    inspectAnyTravel: yup.bool(),
    manualManagement: yup.bool(),
    reviews: yup.bool(),
    search: yup.bool(),
    selfSearch: yup.bool(),
  });
};

export const document = () => {
  return yup.object().shape({
    birthDate: yup.string().nullable(), //"1994-11-05",
    createdAt: yup.string().nullable(), //"2021-01-22T16:47:45.000Z",
    documentNumber: yup.string().required("Digite o número do document."),
    documentToken: yup.string().nullable(), //"b2b8f2e0-5cea-11eb-b5b9-3d7a51c8aca9",
    expirationDate: yup.string().nullable(),
    firstName: yup.string().required("Digite o nome presente no documento."),
    issueDate: yup.date().nullable(),
    issuingCountry: yup.string().nullable(), //"BR",
    lastName: yup
      .string()
      .required("Digite o sobrenome presente no documento."),
    nationality: yup.string().nullable(), //"BR",
    residenceCountry: yup.string().nullable(),
    sex: yup.string().oneOf(Object.values(GenderType)), //"M",
    token: yup.string().nullable(),
    type: yup.string().oneOf(Object.values(DOCUMENT_TYPES)).required(),
    updatedAt: yup.date().nullable(), //"2021-01-22T16:47:45.000Z",
    userToken: yup.string().required(), //"047e4d60-5128-11eb-80d2-bf28910f299a",
  });
};

export const cardNumber = () => {
  return yup.string().test("", "Número do cartão inválido.", (cardNumber) => {
    if (cardNumber === undefined) {
      return true;
    }

    const numberWithoutSpaces = removeBlankSpaces(cardNumber);

    if (!numberWithoutSpaces) {
      return false;
    }

    const issuer = CARD_ISSUERS.find(
      (item) => !!numberWithoutSpaces.match(item.startsWith),
    );

    return !!(issuer && numberWithoutSpaces.match(issuer.validationPattern));
  });
};

export const cpf = () => {
  return yup.string().test("validateCpf", "Digite um CPF válido.", (cpf) => {
    if (!cpf) {
      return false;
    }

    return validateCPF(cpf);
  });
};

export const email = () => {
  return yup.string().email("Digite um email válido.");
};

export const phone = () => {
  return yup.string().test("", "Digite um telefone válido", (phone) => {
    if (!phone) {
      return true;
    }

    const formattedPhone = formatPhone(phone);

    return !!(formattedPhone && !(formattedPhone.length < 11));
  });
};

export const requiredSelectOption = (requiredMessage: string) => {
  return yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .test("", requiredMessage, (option) => {
      return !!option.label && !!option.value;
    });
};

export const rg = () => {
  return yup.string().test("", "Digite um RG válido", (rg) => {
    if (!rg) {
      return false;
    }

    return validateRG(rg);
  });
};

export const selectOption = () => {
  return yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  });
};
