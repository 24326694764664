import React, { useMemo } from "react";
import { useQueryClient } from "react-query";

import { useCards } from "~/apps/corporate/contexts/cards.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { RegisterSettingsServiceStatus } from "~/apps/corporate/dtos/client.dto";
import { Card } from "~/apps/corporate/models/card.model";
import { ClientConfigModel } from "~/apps/corporate/models/client.model";
import { invalidateBillingProfilePayableCardsForTravel } from "~/apps/corporate/pages/travels/payment/payment-content/payment-decision/payment-payment.hooks";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";

import { Button } from "@toolkit/v2";

import {
  editPaymentMethodSchema,
  EditPaymentMethodSchema,
} from "./edit-payment-method.schema";
import { useEditPaymentMethodTranscription } from "./edit-payment-method.transcription";
import { styles } from "./styles";

type Transcription = ReturnType<
  typeof useEditPaymentMethodTranscription
>["transcription"];

const createServiceTypeOptions = ({
  clientConfigModel,
  transcription,
}: {
  clientConfigModel: ClientConfigModel;
  transcription: Transcription;
}) => {
  const registerSettings = clientConfigModel.getCorpIndividualCardsRegisterSettings();

  return [
    {
      status: registerSettings.services.bus,
      key: "enableToBus",
      label: transcription.services.bus,
    },
    {
      status: registerSettings.services.car,
      key: "enableToCar",
      label: transcription.services.car,
    },
    {
      status: registerSettings.services.flight,
      key: "enableToFlight",
      label: transcription.services.flight,
    },
    {
      status: registerSettings.services.hotel,
      key: "enableToHotel",
      label: transcription.services.hotel,
    },
    {
      status: registerSettings.services.ride,
      key: "enableToRide",
      label: transcription.services.ride,
    },
    {
      status: registerSettings.services.other,
      key: "enableToOther",
      label: transcription.services.other,
    },
  ] as const;
};

type Props = {
  back: () => void;
  card: Card;
};

export const EditPaymentMethod: React.FC<Props> = ({ back, card }) => {
  const queryClient = useQueryClient();

  const { transcription } = useEditPaymentMethodTranscription();

  const { editCard, isLoadingCreateCard, isLoadingEditCard } = useCards();
  const { clientConfig } = useClientConfig();

  const defaultServiceTypeValues = {
    enableToBus: card.serviceTypes.bus,
    enableToCar: card.serviceTypes.car,
    enableToFlight: card.serviceTypes.flight,
    enableToHotel: card.serviceTypes.hotel,
    enableToOther: card.serviceTypes.other,
    enableToRide: card.serviceTypes.ride,
  };

  const form = useForm<EditPaymentMethodSchema>({
    defaultValues: {
      allowApprovers: card.allowApprovers,
      description: card.description,
      enableToClient: !!card.billingProfileToken,
      ...defaultServiceTypeValues,
    },
    onSubmit: async () => {
      const values = form.getValues();

      const sucess = await editCard({
        allowApprovers: values.allowApprovers,
        description: values.description,
        enableToBus: values.enableToBus,
        enableToCar: values.enableToCar,
        enableToClient: values.enableToClient,
        enableToFlight: values.enableToFlight,
        enableToHotel: values.enableToHotel,
        enableToOther: values.enableToOther,
        enableToRide: values.enableToRide,
        token: card.token,
      });

      if (!sucess) {
        return;
      }

      void invalidateBillingProfilePayableCardsForTravel(queryClient);

      back();
    },
    schema: editPaymentMethodSchema,
  });

  const serviceTypeOptions = useMemo(() => {
    if (!clientConfig) {
      return [];
    }

    return createServiceTypeOptions({
      clientConfigModel: clientConfig,
      transcription: transcription,
    });
  }, [clientConfig, transcription]);

  const unavailableServiceTypeOptions = useMemo(() => {
    return serviceTypeOptions.filter(
      (option) => option.status === RegisterSettingsServiceStatus.DISABLED,
    );
  }, [serviceTypeOptions]);

  const requiredServiceTypeOptions = useMemo(() => {
    return serviceTypeOptions.filter(
      (option) => option.status === RegisterSettingsServiceStatus.REQUIRED,
    );
  }, [serviceTypeOptions]);

  const hasInconsistencies = useMemo(() => {
    if (!clientConfig) {
      return false;
    }

    const registerSettings = clientConfig.getCorpIndividualCardsRegisterSettings();
    const services = [
      { key: "enableToBus", service: "bus" },
      { key: "enableToCar", service: "car" },
      { key: "enableToFlight", service: "flight" },
      { key: "enableToHotel", service: "hotel" },
      { key: "enableToRide", service: "ride" },
      { key: "enableToOther", service: "other" },
    ];

    return services.some(({ key, service }) => {
      const isEnabled =
        defaultServiceTypeValues[key as keyof typeof defaultServiceTypeValues];
      const serviceStatus =
        registerSettings.services[
          service as keyof typeof registerSettings.services
        ];

      return (
        (isEnabled &&
          serviceStatus === RegisterSettingsServiceStatus.DISABLED) ||
        (!isEnabled && serviceStatus === RegisterSettingsServiceStatus.REQUIRED)
      );
    });
  }, [defaultServiceTypeValues, clientConfig]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.body.root}>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>
            {transcription.fields.cardNickname.label}
          </span>
          <Form.Field
            name="description"
            render={({ onChange, value }) => (
              <Input
                css={styles.body.input.input}
                onChange={onChange}
                placeholder={transcription.fields.cardNickname.placeholder}
                value={value}
              />
            )}
          />
          <InputErrorMessage>
            {form.errors["description"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>
            {transcription.fields.cardPurposes}
          </span>
          <div css={styles.body.services.root}>
            {serviceTypeOptions.map((option) => {
              const isDisabled = hasInconsistencies
                ? false
                : option.status === RegisterSettingsServiceStatus.DISABLED ||
                  option.status === RegisterSettingsServiceStatus.REQUIRED;

              return (
                <label css={styles.body.services.checkbox} key={option.key}>
                  <Checkbox
                    checked={form.watch(option.key)}
                    disabled={card.personal ? isDisabled : false}
                    onChange={(e) => {
                      form.setValue(option.key, e.target.checked);
                    }}
                    variant="pink"
                  />
                  <span
                    css={styles.body.services.label({
                      isDisabled: card.personal ? isDisabled : false,
                    })}
                  >
                    {option.label}
                  </span>
                </label>
              );
            })}
          </div>
          {form.errors["at-least-one-service-enabled"] ? (
            <InputErrorMessage>
              {form.errors["at-least-one-service-enabled"].message}
            </InputErrorMessage>
          ) : null}
          {(unavailableServiceTypeOptions.length > 0 ||
            requiredServiceTypeOptions.length > 0) &&
          card.personal ? (
            <span css={styles.body.services.info}>
              {transcription.unavailableServiceTypeOptionsInfo(
                unavailableServiceTypeOptions.map(
                  (unavailableServiceTypeOption) =>
                    unavailableServiceTypeOption.label.toLowerCase(),
                ),
                requiredServiceTypeOptions.map((requiredServiceTypeOption) =>
                  requiredServiceTypeOption.label.toLowerCase(),
                ),
              )}
            </span>
          ) : null}
        </div>
        <label css={styles.body.services.checkbox}>
          <Checkbox
            checked={form.watch("allowApprovers")}
            onChange={(e) => {
              form.setValue("allowApprovers", e.target.checked);
            }}
            variant="pink"
          />
          <span
            css={styles.body.services.label({
              isDisabled: false,
            })}
          >
            {transcription.fields.allowApprovers}
          </span>
        </label>
      </div>
      <div css={styles.footer.root}>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          fill="outlined"
          onClick={() => {
            back();
          }}
          type="button"
        >
          {transcription.buttons.cancel}
        </Button>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          variant="pink"
        >
          {transcription.buttons.save}
        </Button>
      </div>
    </Form>
  );
};
