import React, { createContext, useCallback, useEffect, useState } from "react";

import { ALERT_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";
import { logger } from "~/apps/shared/utils/logger";

import {
  FeatureVisibilities,
  GetClientConfigResponse,
  CorpIndividualCards,
} from "../dtos/client.dto";
import { ClientConfig, ClientConfigModel } from "../models/client.model";
import { useApplication } from "./application.context";
import * as clientConfigService from "./client-config.service";
import { useUser } from "./user.context";

interface Actions {
  handleUpdateBudgetsActive: (active: boolean) => void;
  handleUpdateCompanyAreaNameDisplay: (nameDisplay: string) => void;
  handleUpdateCompanyAreaEnabled: (enabled: boolean) => void;
  handleUpdateCompanyBoardNameDisplay: (nameDisplay: string) => void;
  handleUpdateCostCenterActive: (active: boolean) => void;
  handleUpdateProjectEnabled: (
    enabled: "off" | "optional" | "required",
  ) => void;
  handleUpdateTagsAdminOnly: (adminOnly: boolean) => void;
  handleUpdateTravelTagsRequired: (required: boolean) => void;
  handleUpdateFeatureVisibilities: (
    featureVisibilities: FeatureVisibilities,
  ) => void;
  handleUpdateTravelExigencies: (
    TravelExigencies: GetClientConfigResponse,
  ) => void;
  handleUpdateCorpIndividualCards: (
    corpIndividualCards: CorpIndividualCards,
  ) => void;
}

type State = {
  errorOnFetch: Error | null;
  isLoading: boolean;
  clientConfig: ClientConfigModel | null;
};

const initialState: State = {
  errorOnFetch: null,
  isLoading: false,
  clientConfig: null,
};

type ContextProps = Actions & State;

export const ClientConfigContext = createContext<ContextProps>({
  ...initialState,
  handleUpdateBudgetsActive: () => {
    return;
  },
  handleUpdateCompanyAreaNameDisplay: () => {
    return;
  },
  handleUpdateCompanyAreaEnabled: () => {
    return;
  },
  handleUpdateCompanyBoardNameDisplay: () => {
    return;
  },
  handleUpdateCostCenterActive: () => {
    return;
  },
  handleUpdateProjectEnabled: () => {
    return;
  },
  handleUpdateTagsAdminOnly: () => {
    return;
  },
  handleUpdateTravelTagsRequired: () => {
    return;
  },
  handleUpdateFeatureVisibilities: () => {
    return;
  },
  handleUpdateTravelExigencies: () => {
    return;
  },
  handleUpdateCorpIndividualCards: () => {
    return;
  },
});

export const ClientConfigProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const changeClientConfig = useCallback(
    (clientConfig: Partial<ClientConfig>) => {
      setState((prev) => {
        const prevClientConfig = prev.clientConfig;

        if (!prevClientConfig) {
          logger.error("expected clientConfig to be defined when updating it");

          return prev;
        }

        return {
          ...prev,
          clientConfig: prevClientConfig.changeClientConfig(clientConfig),
        };
      });
    },
    [],
  );

  const fetchClientConfig = useCallback(async () => {
    if (!user) {
      return;
    }

    const clientToken = user.getClientToken();

    if (!clientToken) {
      logger.error("clientToken not found");

      return;
    }

    setState((prev) => ({
      ...prev,
      errorOnFetch: null,
      isLoading: true,
    }));

    const clientConfigResponse = await clientConfigService.getClientConfig(
      clientToken,
    );

    if (clientConfigResponse.error) {
      const error = clientConfigResponse.error;

      setState((prev) => ({
        ...prev,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    if (!clientConfigResponse.data) {
      const error = ERROR.UNEXPECTED;

      setState((prev) => ({
        ...prev,
        errorOnFetch: error,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return false;
    }

    setState((prev) => ({
      ...prev,
      clientConfig: clientConfigResponse.data!,
      isLoading: false,
    }));
  }, [user]);

  const handleUpdateBudgetsActive = (active: boolean) => {
    changeClientConfig({ budgetsActive: active });
  };

  const handleUpdateCompanyAreaNameDisplay = useCallback(
    (nameDisplay: string) => {
      changeClientConfig({ companyAreaDisplay: nameDisplay });
    },
    [changeClientConfig],
  );

  const handleUpdateCompanyAreaEnabled = useCallback(
    (enabled: boolean) => {
      changeClientConfig({ companyAreaEnabled: enabled });
    },
    [changeClientConfig],
  );

  const handleUpdateCompanyBoardNameDisplay = useCallback(
    (nameDisplay: string) => {
      changeClientConfig({ companyBoardDisplay: nameDisplay });
    },
    [changeClientConfig],
  );

  const handleUpdateCostCenterActive = useCallback(
    (active: boolean) => {
      changeClientConfig({ costCenterActive: active });
    },
    [changeClientConfig],
  );

  const handleUpdateProjectEnabled = useCallback(
    (status: "off" | "optional" | "required") => {
      changeClientConfig({ projectEnablingState: status });
    },
    [],
  );

  const handleUpdateTagsAdminOnly = useCallback(
    (adminOnly: boolean) => {
      changeClientConfig({ tagsAdminOnly: adminOnly });
    },
    [changeClientConfig],
  );

  const handleUpdateTravelTagsRequired = useCallback(
    (required: boolean) => {
      changeClientConfig({ travelTagsRequired: required });
    },
    [changeClientConfig],
  );

  const handleUpdateFeatureVisibilities = useCallback(
    (featureVisibilities: FeatureVisibilities) => {
      changeClientConfig({ featureVisibilities });
    },
    [changeClientConfig],
  );

  const handleUpdateTravelExigencies = useCallback(
    (clientConfig: GetClientConfigResponse) => {
      changeClientConfig({
        companyAreaEnabled: clientConfig.company_area_enabled,
        costCenterActive: clientConfig.cost_center_active,
        projectEnablingState: clientConfig.project_enabling_state,
        bookingPhoneConfig: clientConfig.booking_phone_config,
        config: {
          flights: clientConfig.config.flights,
          travels: clientConfig.config.travels,
        },
      });
    },
    [changeClientConfig],
  );

  const handleUpdateCorpIndividualCards = useCallback(
    (corpIndividualCards: CorpIndividualCards) => {
      changeClientConfig({ corpIndividualCards });
    },
    [changeClientConfig],
  );

  useEffect(() => {
    void fetchClientConfig();
  }, [fetchClientConfig]);

  return (
    <ClientConfigContext.Provider
      value={{
        ...state,
        handleUpdateBudgetsActive,
        handleUpdateCompanyAreaNameDisplay,
        handleUpdateCompanyAreaEnabled,
        handleUpdateCompanyBoardNameDisplay,
        handleUpdateCostCenterActive,
        handleUpdateProjectEnabled,
        handleUpdateTagsAdminOnly,
        handleUpdateTravelTagsRequired,
        handleUpdateFeatureVisibilities,
        handleUpdateTravelExigencies,
        handleUpdateCorpIndividualCards,
      }}
    >
      {children}
    </ClientConfigContext.Provider>
  );
};

export const useClientConfig = useContextFactory(
  "ClientConfigContext",
  ClientConfigContext,
);
