import React, { useCallback, useEffect, useMemo } from "react";

import { useLoyaltyPrograms } from "~/apps/corporate/contexts/loyalty-program.context";
import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";

import { useUserProfileDrawer } from "../user-profile-drawer.context";
import { useRemoveLoyaltyProgramAlertDialogTranscription } from "./remove-loyalty-program-alert-dialog.transcription";

export const RemoveLoyaltyProgramAlertDialog: React.FC = () => {
  const {
    errorOnFetchRemoveLoyaltyProgram,
    isLoadingRemoveLoyaltyProgram,
    removeLoyaltyProgram,
  } = useLoyaltyPrograms();
  const {
    closeRemoveLoyaltyProgramAlertDialog,
    isRemoveLoyaltyProgramAlertDialogOpen,
    selectedLoyaltyProgram,
  } = useUserProfileDrawer();

  const { transcription } = useRemoveLoyaltyProgramAlertDialogTranscription();

  const handleClose = useCallback(() => {
    closeRemoveLoyaltyProgramAlertDialog();
  }, [closeRemoveLoyaltyProgramAlertDialog]);

  const handleConfirm = useCallback(async () => {
    if (!selectedLoyaltyProgram) {
      closeRemoveLoyaltyProgramAlertDialog();
      return;
    }

    await removeLoyaltyProgram(selectedLoyaltyProgram);
    closeRemoveLoyaltyProgramAlertDialog();
  }, [
    closeRemoveLoyaltyProgramAlertDialog,
    removeLoyaltyProgram,
    selectedLoyaltyProgram,
  ]);

  const translatedLoyaltyProgramName = useMemo(() => {
    if (!selectedLoyaltyProgram) {
      return null;
    }

    return selectedLoyaltyProgram.supplierLoyaltyProgram.name;
  }, [selectedLoyaltyProgram]);

  useEffect(() => {
    if (!errorOnFetchRemoveLoyaltyProgram) {
      return;
    }

    handleClose();
  }, [errorOnFetchRemoveLoyaltyProgram, handleClose]);

  return (
    <AlertDialog
      confirmLabel={transcription.buttons.confirm}
      isLoading={isLoadingRemoveLoyaltyProgram}
      message={transcription.message(translatedLoyaltyProgramName)}
      onClose={handleClose}
      onConfirm={handleConfirm}
      open={isRemoveLoyaltyProgramAlertDialogOpen}
      title={transcription.title}
    />
  );
};
