import React from "react";

import { Router } from "@reach/router";
import { CardsProvider } from "~/apps/corporate/contexts/cards.context";
import { ClientConfigProvider } from "~/apps/corporate/contexts/client-config.context";
import { DocumentsProvider } from "~/apps/corporate/contexts/documents.context";
import { LoyaltyProgramsProvider } from "~/apps/corporate/contexts/loyalty-program.context";
import { MemberGetMemberProvider } from "~/apps/corporate/contexts/member-get-member.context";
import { UserProvider } from "~/apps/corporate/contexts/user.context";
import { Login } from "~/apps/corporate/pages/login/login";
import { NewTrip } from "~/apps/corporate/pages/new-trip/new-trip";
import { Trips } from "~/apps/corporate/pages/trips/trips";
import { CapabilityProtectedRoute } from "~/apps/shared/components/capability-protected-route/capability-protected-route";
import { Livechat } from "~/apps/shared/components/livechat/livechat";
import { capabilities } from "~/apps/shared/constants/enums";

import { ClientProvider } from "./apps/corporate/contexts/client.context";
import { CurrencyProvider } from "./apps/corporate/contexts/currency.context";
import { LanguageProvider } from "./apps/corporate/contexts/language.context";
import { LivechatProvider } from "./apps/corporate/contexts/livechat.context";
import { OIDCCallback } from "./apps/corporate/pages/oidc-callback/oidc-callback";
import { Password } from "./apps/corporate/pages/password/password";
import { SSOLogin } from "./apps/corporate/pages/sso-login/sso-login";
import { LazyLoadedRoute } from "./apps/shared/components/lazy-loaded-route/lazy-loaded-route";
import { ScriptDownloader } from "./apps/shared/components/livechat/script-downloader/script-downloader";
import {
  AnalyticsProtectedRoute,
  EventsProtectedRoute,
  ExpensesProtectedRoute,
} from "./apps/shared/components/protected-routes";

const OldPrivateRoutes: React.FC = () => {
  const Approvals = React.lazy(() =>
    import("./components/approvals").then((module) => ({
      default: module.Approvals,
    })),
  );
  const Events = React.lazy(() =>
    import("./components/events").then((module) => ({
      default: module.Events,
    })),
  );
  const ExpenseAdvanceApprovalReview = React.lazy(() =>
    import("./components/expense-advance-approval-review").then((module) => ({
      default: module.ExpenseAdvanceApprovalReview,
    })),
  );
  const ExpenseReport = React.lazy(() =>
    import("./components/expenses/Report/report").then((module) => ({
      default: module.Report,
    })),
  );
  const ExpenseReportApprovalReview = React.lazy(() =>
    import(
      "./components/expenses/ReportApprovalReview/ReportApprovalReview"
    ).then((module) => ({ default: module.ReportApprovalReview })),
  );
  const Expenses = React.lazy(() =>
    import("./components/expenses/Expenses").then((module) => ({
      default: module.Expenses,
    })),
  );
  const Financial = React.lazy(() =>
    import("./components/financial/Financial").then((module) => ({
      default: module.Financial,
    })),
  );
  const HotelBookingConfirmation = React.lazy(() =>
    import("./components/hotel-booking-confirmation").then((module) => ({
      default: module.HotelBookingConfirmation,
    })),
  );
  const SupportEvaluation = React.lazy(() =>
    import("./components/support-evaluation").then((module) => ({
      default: module.SupportEvaluation,
    })),
  );
  const UserReviews = React.lazy(() =>
    import("./components/user-reviews").then((module) => ({
      default: module.UserReviews,
    })),
  );

  return (
    <Router primary={false}>
      <LazyLoadedRoute component={Approvals} path="/approvals/*" />
      <EventsProtectedRoute component={Events} path="/events/*" />
      <LazyLoadedRoute
        component={ExpenseAdvanceApprovalReview}
        path="/expense-advance/:expenseAdvanceToken/approval-review"
      />
      <LazyLoadedRoute
        component={ExpenseReportApprovalReview}
        path="/reports/:reportToken/approval-review"
      />
      <LazyLoadedRoute component={ExpenseReport} path="/reports/:reportToken" />
      <ExpensesProtectedRoute component={Expenses} path="/expenses/*" />
      <CapabilityProtectedRoute
        capabilities={[capabilities.FINANCIAL]}
        component={Financial}
        path="/financial"
      />
      <LazyLoadedRoute
        component={HotelBookingConfirmation}
        path="/hotel-booking-confirmation/:hotelNegotiatedBookingRequestToken"
      />
      <LazyLoadedRoute
        component={SupportEvaluation}
        path="/support-evaluation/:supportEvaluationToken"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.REVIEWS]}
        component={UserReviews}
        path="/user-reviews/*"
      />
    </Router>
  );
};

const PrivateRoutes: React.FC = () => {
  const Analytics = React.lazy(() =>
    import("./apps/corporate/pages/analytics/analytics").then((module) => ({
      default: module.Analytics,
    })),
  );
  const Configurations = React.lazy(() =>
    import(
      "./apps/corporate/pages/configurations/configurations"
    ).then((module) => ({ default: module.Configurations })),
  );
  const Error = React.lazy(() =>
    import("./apps/corporate/pages/error/error").then((module) => ({
      default: module.Error,
    })),
  );
  const Offers = React.lazy(() =>
    import("./apps/corporate/pages/offers/offers").then((module) => ({
      default: module.Offers,
    })),
  );
  const Travels = React.lazy(() =>
    import("./apps/corporate/pages/travels/travels").then((module) => ({
      default: module.Travels,
    })),
  );

  return (
    <Router primary={false}>
      <CapabilityProtectedRoute
        atLeastOne
        capabilities={[capabilities.SEARCH, capabilities.SELF_SEARCH]}
        component={NewTrip}
        path="/"
      />
      <AnalyticsProtectedRoute component={Analytics} path="/analytics/*" />
      <CapabilityProtectedRoute
        atLeastOne
        capabilities={[
          capabilities.CONFIGURATIONS,
          capabilities.EXTERNAL_CREDENTIALS,
        ]}
        component={Configurations}
        path="/configurations/*"
      />
      <CapabilityProtectedRoute
        capabilities={[capabilities.BASIC]}
        component={Error}
        path="/error"
      />
      <LazyLoadedRoute component={Offers} path="/offers/*" />
      <LazyLoadedRoute component={Travels} path="/travels/*" />
      <CapabilityProtectedRoute
        capabilities={[capabilities.BASIC]}
        component={Trips}
        path="/trips"
      />
    </Router>
  );
};

const PublicRoutes: React.FC = () => {
  const Permission = React.lazy(() =>
    import("./apps/corporate/pages/permission/permission").then((module) => ({
      default: module.Permission,
    })),
  );

  return (
    <Router primary={false}>
      <Login path="/login" />
      <OIDCCallback path="/oidc-callback" />
      <Password path="/password/*" />
      <LazyLoadedRoute component={Permission} path="/permission" />
      <SSOLogin path="/sso-login/*" />
    </Router>
  );
};

export const Routes: React.FC = () => {
  return (
    <>
      <UserProvider>
        <LanguageProvider>
          <CurrencyProvider>
            <ClientProvider>
              <ClientConfigProvider>
                <LivechatProvider>
                  <CardsProvider>
                    <DocumentsProvider>
                      <LoyaltyProgramsProvider>
                        <MemberGetMemberProvider>
                          <OldPrivateRoutes />
                          <PrivateRoutes />
                        </MemberGetMemberProvider>
                      </LoyaltyProgramsProvider>
                    </DocumentsProvider>
                  </CardsProvider>
                  <Livechat />
                  <ScriptDownloader />
                </LivechatProvider>
              </ClientConfigProvider>
            </ClientProvider>
            <PublicRoutes />
          </CurrencyProvider>
        </LanguageProvider>
      </UserProvider>
    </>
  );
};
