import React, { useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { useCards } from "~/apps/corporate/contexts/cards.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { Card } from "~/apps/corporate/models/card.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Menu } from "~/apps/shared/components/menu/menu";
import { MenuItem } from "~/apps/shared/components/menu/menu-item/menu-item";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";
import { CARD_ISSUERS } from "~/apps/shared/constants/credit-card.constant";

import { useUserProfileDrawer } from "../../../user-profile-drawer.context";
import { useUserProfileDrawerCardsSectionTranscription } from "./cards-section.transcription";
import { styles } from "./styles";

export const UserProfileDrawerCardsSection: React.FC = () => {
  const { transcription } = useUserProfileDrawerCardsSectionTranscription();

  const { cards, isLoading } = useCards();
  const { clientConfig } = useClientConfig();

  const {
    addPaymentMethod,
    editPaymentMethod,
    openRemovePaymentMethodAlertDialog,
  } = useUserProfileDrawer();

  const [
    moreOptionsPopoverAnchorElement,
    setMoreOptionsPopoverAnchorElement,
  ] = useState<HTMLElement | null>(null);
  const [selectedPaymentMethod, setSelectedCard] = useState<Card | null>(null);

  const handleCloseMoreOptionsPopover = useCallback(() => {
    setMoreOptionsPopoverAnchorElement(null);
    setSelectedCard(null);
  }, []);

  const handleOpenMoreOptionsPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>, card: Card) => {
      setMoreOptionsPopoverAnchorElement(event.currentTarget);
      setSelectedCard(card);
    },
    [],
  );

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          <span css={styles.header.title}>{transcription.title}</span>
          {clientConfig?.isCorpIndividualCardsRegisterEnabled() ? (
            <button
              css={styles.header.button({ isDisabled: false })}
              onClick={() => {
                addPaymentMethod();
              }}
            >
              {transcription.link}
            </button>
          ) : (
            <Tooltip
              content={transcription.tooltipWarning}
              position="top"
              arrow
            >
              <button
                css={styles.header.button({ isDisabled: true })}
                onClick={() => {
                  addPaymentMethod();
                }}
                disabled
              >
                {transcription.link}
              </button>
            </Tooltip>
          )}
        </div>
        <div css={styles.cards.root}>
          {cards && cards.length > 0 ? (
            cards.map((card) => (
              <UserProfileDrawerCardsSectionCreditCard
                card={card}
                handleOpenMoreOptionsPopover={handleOpenMoreOptionsPopover}
                key={card.token}
              />
            ))
          ) : isLoading ? (
            <Skeleton height="132px" width="352px" />
          ) : (
            <span css={styles.cards.empty}>{transcription.empty}</span>
          )}
        </div>
      </div>
      <Menu
        anchorEl={moreOptionsPopoverAnchorElement}
        onClose={handleCloseMoreOptionsPopover}
        open={!!moreOptionsPopoverAnchorElement}
      >
        <MenuItem
          onClick={() => {
            if (!selectedPaymentMethod) {
              return;
            }

            editPaymentMethod(selectedPaymentMethod);

            handleCloseMoreOptionsPopover();
          }}
        >
          {transcription.menu.edit}
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (!selectedPaymentMethod) {
              return;
            }

            openRemovePaymentMethodAlertDialog(selectedPaymentMethod);

            handleCloseMoreOptionsPopover();
          }}
        >
          {transcription.menu.remove}
        </MenuItem>
      </Menu>
    </>
  );
};

type UserProfileDrawerCardsSectionCreditCardProps = {
  card: Card;
  handleOpenMoreOptionsPopover: (
    event: React.MouseEvent<HTMLElement>,
    card: Card,
  ) => void;
};

const UserProfileDrawerCardsSectionCreditCard: React.FC<UserProfileDrawerCardsSectionCreditCardProps> = ({
  card,
  handleOpenMoreOptionsPopover,
}) => {
  const issuer = CARD_ISSUERS.find((issuer) => issuer.brand === card.brand);

  return (
    <div css={styles.cards.card.root}>
      <div css={styles.cards.card.top.root}>
        <div css={styles.cards.card.top.left.root}>
          {issuer ? (
            <img
              alt={issuer.brand}
              width={32}
              src={issuer.logo}
              title={issuer.brand}
            />
          ) : null}
        </div>
        <div css={styles.cards.card.top.right.root}>
          {card.personal ? (
            <Tooltip
              content={
                card.allowApprovers
                  ? "Cartão pessoal à disposição de aprovadores e solicitantes."
                  : "Cartão pessoal."
              }
            >
              {card.allowApprovers ? (
                <Icon size={16} use="people" />
              ) : (
                <Icon size={16} use="person" />
              )}
            </Tooltip>
          ) : (
            <>
              {card.billingProfileToken ? (
                <Tooltip content="Vinculado a um CNPJ.">
                  <Icon size={16} use="share-nodes" />
                </Tooltip>
              ) : null}
              <Tooltip content="Cartão da empresa.">
                <Icon size={16} use="building" />
              </Tooltip>
            </>
          )}
          <button
            css={styles.cards.card.top.button}
            onClick={(event) => {
              handleOpenMoreOptionsPopover(event, card);
            }}
          >
            <Icon size={16} use="ellipsis-vertical" />
          </button>
        </div>
      </div>
      <div css={styles.cards.card.center.root}>
        <span css={styles.cards.card.center.description}>
          {card.description}
        </span>
        <span css={styles.cards.card.center.number}>
          •••• •••• •••• {card.lastDigits}
        </span>
      </div>
      <div css={styles.cards.card.bottom.root}>
        <span css={styles.cards.card.bottom.name}>{card.holderName}</span>
        <div css={styles.cards.card.bottom.expiration.root}>
          <span css={styles.cards.card.bottom.expiration.label}>
            Válido até:
          </span>
          <span css={styles.cards.card.bottom.expiration.value}>
            {card.expirationDate}
          </span>
        </div>
      </div>
      <UserProfileDrawerCardsSectionCreditCardServiceTypes card={card} />
    </div>
  );
};

type UserProfileDrawerCardsSectionCreditCardServiceTypesProps = {
  card: Card;
};

const UserProfileDrawerCardsSectionCreditCardServiceTypes: React.FC<UserProfileDrawerCardsSectionCreditCardServiceTypesProps> = ({
  card,
}) => {
  return (
    <div css={styles.cards.card.services.root}>
      <Tooltip content="Aéreo">
        <Icon
          css={styles.cards.card.services.icon({
            enabled: card.serviceTypes.flight,
          })}
          size={16}
          use="airplane-taking-off"
        />
      </Tooltip>
      <Tooltip content="Ônibus">
        <Icon
          css={styles.cards.card.services.icon({
            enabled: card.serviceTypes.bus,
          })}
          size={16}
          use="bus"
        />
      </Tooltip>
      <Tooltip content="Carro">
        <Icon
          css={styles.cards.card.services.icon({
            enabled: card.serviceTypes.car,
          })}
          size={16}
          use="car"
        />
      </Tooltip>
      <Tooltip content="Táxi">
        <Icon
          css={styles.cards.card.services.icon({
            enabled: card.serviceTypes.ride,
          })}
          size={16}
          use="taxi"
        />
      </Tooltip>
      <Tooltip content="Hotel">
        <Icon
          css={styles.cards.card.services.icon({
            enabled: card.serviceTypes.hotel,
          })}
          size={16}
          use="hotel"
        />
      </Tooltip>
      <Tooltip content="Fee de Viagem e Outros">
        <Icon
          css={styles.cards.card.services.icon({
            enabled: card.serviceTypes.other,
          })}
          size={16}
          use="cogwheel-filled"
        />
      </Tooltip>
    </div>
  );
};
