import * as loyaltyProgramApi from "~/apps/corporate/apis/loyalty-program.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";

import { getLoggedUserToken } from "~/helpers/user.helper";

import {
  SaveLoyaltyProgramDto,
  UpdateLoyaltyProgramDto,
} from "../dtos/loyalty-program.dto";
import {
  CompleteLoyaltyProgram,
  SupplierLoyaltyProgram,
} from "../models/loyalty-program.model";
import { UserLoyaltyProgramFormData } from "./loyalty-program.context";

export const createLoyaltyProgram = async (
  data: UserLoyaltyProgramFormData,
) => {
  const result: { data?: CompleteLoyaltyProgram; error?: Error } = {};

  try {
    const createRequestData: SaveLoyaltyProgramDto = {
      number: data.number,
      supplier_loyalty_program_token: data.supplierLoyaltyProgramToken,
      user_token: getLoggedUserToken(),
    };

    result.data = await loyaltyProgramApi.createLoyaltyProgram(
      createRequestData,
    );
  } catch (error: any) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_CREATE_LOYALTY_PROGRAM_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_CREATE_LOYALTY_PROGRAM_ERROR;
    }
  }
  return result;
};

export const editLoyaltyProgram = async (
  data: UserLoyaltyProgramFormData,
  loyaltyProgramToken: string,
) => {
  const result: { data?: CompleteLoyaltyProgram; error?: Error } = {};

  try {
    const updateRequestData: UpdateLoyaltyProgramDto = {
      number: data.number,
    };

    result.data = await loyaltyProgramApi.updateLoyaltyProgram(
      updateRequestData,
      loyaltyProgramToken,
    );
  } catch (error: any) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_EDIT_LOYALTY_PROGRAM_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_EDIT_LOYALTY_PROGRAM_ERROR;
    }
  }

  return result;
};

export const getUserLoyaltyPrograms = async (userToken: string) => {
  const result: { data?: CompleteLoyaltyProgram[]; error?: Error } = {};

  try {
    result.data = await loyaltyProgramApi.getUserLoyaltyPrograms(userToken);
  } catch (error: any) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_GET_LOYALTY_PROGRAMS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_GET_LOYALTY_PROGRAMS_ERROR;
    }
  }

  return result;
};

export const inactiveLoyaltyProgram = async (loyaltyProgramToken: string) => {
  const result: { data?: any; error?: Error } = {};

  try {
    result.data = await loyaltyProgramApi.inactiveLoyaltyProgram(
      loyaltyProgramToken,
    );
  } catch (error: any) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_DELETE_LOYALTY_PROGRAM_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_DELETE_LOYALTY_PROGRAM_ERROR;
    }
  }

  return result;
};

export const getSupplierLoyaltyPrograms = async () => {
  const result: { data?: SupplierLoyaltyProgram[]; error?: Error } = {};

  try {
    result.data = await loyaltyProgramApi.getSupplierLoyaltyPrograms();
  } catch (error: any) {
    result.error = ERROR.UNEXPECTED_GET_LOYALTY_PROGRAMS_ERROR;
  }

  return result;
};
