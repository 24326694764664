import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    buttons: {
      search: "Search cars",
    },
    dropoff: {
      date: {
        placeholder: "Drop-off date*",
      },
      location: {
        empty: "No place found...",
        label: "Drop-off*",
        placeholder: "Type in the drop-off location...",
      },
    },
    pickup: {
      date: {
        placeholder: "Pickup date*",
      },
      location: {
        empty: "No place found...",
        label: "Pickup*",
        placeholder: "Type in the pickup location...",
      },
    },
    sameLocation: "Return at the same location",
    agencyPickUpPlaceholder: "Select the pickup agency*",
    agencyPickUpPlaceholderDisabled: "Fill in the reference point of pickup*",
    agencyDropOffPlaceholder: "Select the drop-off agency*",
    agencyDropOffPlaceholderDisabled:
      "Fill in the reference point of drop-off*",
    searchByAgency:
      "Search for a specific agency starting from a reference point.",
    alert: "*Car rentals are only available in Brazil.",
    form: {
      errors: {
        pickupAgency: "Select the pickup agency",
        dropoffAgency: "Select the drop-off agency",
      },
    },
  },
  [LanguageCode.PT_BR]: {
    buttons: {
      search: "Pesquisar carros",
    },
    dropoff: {
      date: {
        placeholder: "Data da devolução*",
      },
      location: {
        empty: "Nenhum local encontrado...",
        label: "Devolução*",
        placeholder: "Digite o local da devolução...",
      },
    },
    pickup: {
      date: {
        placeholder: "Data da retirada*",
      },
      location: {
        empty: "Nenhum local encontrado...",
        label: "Retirada*",
        placeholder: "Digite o local da retirada...",
      },
    },
    sameLocation: "Devolver no mesmo local",
    agencyPickUpPlaceholder: "Selecione a agência de retirada*",
    agencyPickUpPlaceholderDisabled:
      "Preencha o ponto de referência de retirada*",
    agencyDropOffPlaceholder: "Selecione a agência de devolução*",
    agencyDropOffPlaceholderDisabled:
      "Preencha o ponto de referência de devolução*",
    searchByAgency:
      "Buscar por agência específica a partir de um ponto de referência.",
    alert:
      "*As buscas de aluguéis de carro estão disponíveis apenas para o Brasil.",
    form: {
      errors: {
        pickupAgency: "Selecione a agência de retirada",
        dropoffAgency: "Selecione a agência de devolução",
      },
    },
  },
};

export const useCarFormTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription = transcriptions[languageCode];

  return { transcription };
};
