import { StylesConfig } from "react-windowed-select";

import { css } from "@styled-system/css";
import { getColors } from "~/apps/shared/components/select/styles";

import { theme } from "@skin/v2";

export const styles = {
  alert: css({
    color: theme.colors.gray[400],
    fontSize: "0.875rem",
    fontStyle: "italic",
  }),
  checkbox: ({ isDisabled }: { isDisabled: boolean }) =>
    css({
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      width: "max-content",
      margin: "0.5rem 0",
      marginLeft: "0.25rem",
      fontSize: "0.875rem",
      color: isDisabled ? theme.colors.gray[400] : theme.colors.gray[700],
      cursor: isDisabled ? "not-allowed" : "pointer",
      "@media (max-width: 640px)": {
        width: "auto",
      },
    }),
  agencyMobile: css({
    display: "none",
    "@media (max-width: 657px)": {
      display: "flex",
      margin: "0.5rem 0",
    },
  }),
  agencyDesktop: css({
    flex: 1,
    "@media (max-width: 657px)": {
      display: "none",
    },
  }),
  button: {
    container: css({
      display: "flex",
      gap: "8px",
      width: "100%",
    }),
    root: css({
      alignItems: "center",
      aspectRatio: 1,
      borderRadius: "50%",
      color: theme.colors.white,
      display: "flex",
      flexShrink: 0,
      height: "57px",
      justifyContent: "center",
      width: "57px",
    }),
  },
  center: {
    bottom: {
      dates: {
        date: {
          root: css({
            border: `1px solid ${theme.colors.gray[100]}`,
            borderRadius: "calc(57px / 2)",
            display: "flex",
            flexDirection: "column",
            padding: "0 16px",
            width: "100%",
          }),
        },
        left: css({
          display: "flex",
          gap: "8px",
          width: "100%",
          "@media screen and (max-width: 425px)": {
            flexDirection: "column",
          },
        }),
        right: css({
          display: "flex",
          gap: "8px",
          width: "100%",
          "@media screen and (max-width: 425px)": {
            flexDirection: "column",
          },
        }),
        root: css({
          display: "flex",
          gap: "8px",
          width: "100%",
          "@media screen and (max-width: 992px)": {
            flexDirection: "column",
          },
        }),
        time: {
          select: ({
            error,
            fill,
            variant,
          }: {
            error?: boolean;
            fill: "filled" | "outlined";
            variant: "blue" | "default" | "green" | "pink";
          }) => {
            return <StylesConfig>{
              control: (styles, state) => ({
                ...styles,
                alignItems: "center",
                border: `1px solid ${theme.colors.gray[100]}`,
                borderRadius: "calc(57px / 2)",
                boxShadow: "none",
                display: "flex",
                minHeight: "unset",
                flexWrap: "nowrap",
                gap: "8px",
                padding: "17.5px 16px",
                transition: "all 0.15s ease-in-out",
                ["&:not(:disabled)"]: {
                  cursor: "pointer",
                },
                ...getColors({ error, fill, variant }).control({ state }),
              }),
              dropdownIndicator: (styles) => ({
                ...styles,
                padding: "0",
                ...getColors({ error, fill, variant }).dropdownIndicator,
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              input: (styles) => ({
                ...styles,
                color: theme.colors.gray[500],
                fontFamily: theme.fonts.inter,
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: theme.colors.white,
                border: `1px solid ${theme.colors.gray[100]}`,
                borderRadius: "8px",
                boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
                zIndex: 10,
              }),
              menuList: (styles) => ({
                ...styles,
                padding: 0,
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 1301,
              }),
              option: (styles, state) => ({
                ...styles,
                fontFamily: theme.fonts.inter,
                fontSize: "0.875rem",
                padding: "12px 16px",
                ["&:disabled"]: {
                  opacity: 0.5,
                  pointerEvents: "none",
                },
                ["&:first-of-type"]: {
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                },
                ["&:last-of-type"]: {
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                },
                ["&:not(:disabled)"]: {
                  cursor: "pointer",
                },
                ...getColors({ error, fill, variant }).option({ state }),
              }),
              placeholder: (styles) => ({
                ...styles,
                fontFamily: theme.fonts.inter,
                ...getColors({ error, fill, variant }).placeholder,
              }),
              singleValue: (styles) => ({
                ...styles,
                fontFamily: theme.fonts.inter,
                whiteSpace: "nowrap",
                ...getColors({ error, fill, variant }).singleValue,
              }),
              valueContainer: (styles) => ({
                display: "flex",
                height: "fit-content",
                width: "fit-content",
              }),
            };
          },
          root: css({
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }),
        },
      },
      root: css({
        alignItems: "center",
        display: "flex",
        gap: "8px",
        width: "100%",
      }),
    },
    control: {
      root: css({
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }),
    },
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }),
    top: {
      location: {
        container: css({
          border: `1px solid ${theme.colors.gray[100]}`,
          borderRadius: "calc(57px / 2)",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }),
        input: {
          input: css({
            width: "100%",
            ".Mui": {
              "&FormLabel-root": {
                color: theme.colors.gray[100],
                fontFamily: theme.fonts.inter,
                "&.Mui-focused": {},
              },
              "&InputBase-input": {
                fontFamily: theme.fonts.inter,
                padding: "26px 16px 12px 12px",
                textOverflow: "ellipsis",
              },
              "&InputLabel-outlined": {
                "&.MuiInputLabel-shrink": {
                  color: theme.colors.gray[700],
                  fontWeight: 700,
                  transform: "translate(12px, 12px) scale(0.75)",
                },
              },
              "&OutlinedInput-notchedOutline": {
                border: "none !important",
              },
            },
          }),
          loading: css({
            flexShrink: 0,
          }),
          root: css({
            alignItems: "center",
            borderRadius: "8px",
            color: theme.colors.gray[700],
            display: "flex",
            paddingLeft: "16px",
            width: "100%",
          }),
        },
        location: css({
          padding: "8px 16px 12px 12px",
        }),
        root: css({
          position: "relative",
          width: "100%",
          "& > :first-of-type": {
            gap: "0",
          },
        }),
      },
      root: css({
        alignItems: "flex-start",
        display: "flex",
        gap: "8px",
        "@media (max-width: 657px)": {
          flexDirection: "column",
          width: "100%",
        },
      }),
    },
  },
  dates: {
    popover: css({
      ".MuiPaper-root": {
        boxShadow: "none",
        backgroundColor: "transparent",
        overflow: "hidden",
        transform: "translateY(16px) !important",
      },
    }),
    root: css({
      ".DayPicker": {
        borderRadius: "8px",
        overflow: "hidden",
        "&Navigation": {
          display: "flex",
          justifyContent: "space-between",
          margin: "0 8px",
          top: "20px",
          "& > :nth-of-type(1)": {
            transform: "rotate(0deg)",
          },
          "& > :nth-of-type(2)": {
            transform: "rotate(0deg)",
          },
          "&_button": {
            alignItems: "center",
            aspectRatio: "1",
            borderRadius: "12px",
            color: theme.colors.gray[300],
            display: "flex",
            height: "24px",
            justifyContent: "center",
            padding: "4px",
            transition: "all 0.15s ease-in-out",
            width: "24px",
            ":hover": {
              color: theme.colors.gray[700],
            },
          },
        },
        "&_weekHeaders": {
          top: "-8px",
        },
      },
    }),
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
  top: {
    control: {
      error: css({
        padding: "0 16px 12px 16px",
        display: "flex",
      }),
      guests: {
        button: css({
          alignItems: "center",
          aspectRatio: 1,
          borderRadius: "50%",
          display: "flex",
          height: "24px",
          justifyContent: "center",
          padding: "0",
          transition: "all 0.15s ease-in-out",
          width: "24px",
          "& path": {
            strokeWidth: "2.5px",
          },
        }),
        root: css({
          alignItems: "center",
          color: theme.colors.gray[700],
          display: "flex",
          gap: "8px",
        }),
        value: css({
          fontWeight: 600,
          minWidth: "12px",
          textAlign: "center",
        }),
      },
      label: css({
        color: theme.colors.gray[700],
        flexShrink: 0,
        "@media screen and (max-width: 768px)": {
          fontSize: "0.875rem",
        },
      }),
      root: css({
        alignItems: "center",
        border: "1px solid",
        borderColor: "transparent",
        borderRadius: "8px",
        display: "flex",
        gap: "4px",
        padding: "10px 16px 10px 16px",
        transition: "all 0.15s ease-in-out",
        ":hover": {
          borderColor: theme.colors.gray[100],
        },
        "@media screen and (max-width: 768px)": {
          minWidth: "unset",
          width: "100%",
        },
      }),
    },
    root: css({
      alignItems: "flex-start",
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
    }),
    switch: {
      root: css({
        alignItems: "center",
        border: "1px solid",
        borderColor: "transparent",
        borderRadius: "8px",
        cursor: "pointer",
        display: "flex",
        gap: "12px",
        padding: "13px 16px",
        transition: "all 0.15s ease-in-out",
        width: "fit-content",
        ":hover": {
          borderColor: theme.colors.gray[100],
        },
      }),
      text: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
        "@media screen and (max-width: 768px)": {
          fontSize: "0.875rem",
        },
      }),
    },
  },
};
