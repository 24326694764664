import { formatServiceError } from "../utils/errors";
import { logger } from "../utils/logger";

export type PollingManagerTask = {
  callback: Function;
  id: string;
  interval: number;
  timerId: NodeJS.Timeout | null;
};

export class PollingManager {
  private active = false;
  private tasks: Map<string, PollingManagerTask> = new Map();

  constructor() {
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  public addTask({
    callback,
    id,
    interval,
  }: Omit<PollingManagerTask, "timerId">): void {
    if (this.tasks.has(id)) {
      logger.error(`task with id ${id} already exists in polling manager`);

      return;
    }

    this.tasks.set(id, {
      callback,
      id,
      interval,
      timerId: null,
    });

    logger.info(`task with id ${id} added to polling manager`);

    if (!this.active) {
      return;
    }

    const task = this.tasks.get(id);

    if (!task) {
      return;
    }

    void this.poll(task);
  }

  private handleVisibilityChange(): void {
    if (document.hidden) {
      logger.info("document is hidden, stopping polling manager");

      this.tasks.forEach((task) => {
        if (!task.timerId) {
          return;
        }

        clearTimeout(task.timerId);

        task.timerId = null;
      });

      return;
    }

    logger.info("document is visible, resuming polling manager");

    this.tasks.forEach((task) => this.poll(task));
  }

  private async poll(task: PollingManagerTask): Promise<void> {
    if (!this.active || document.hidden) {
      return;
    }

    try {
      await task.callback();
    } catch (error) {
      logger.error(
        `error when polling for task ${task.id}: ${
          formatServiceError({ error }).description
        }`,
      );
    }

    task.timerId = setTimeout(() => this.poll(task), task.interval);
  }

  public remove(id: string): void {
    const task = this.tasks.get(id);

    if (task && task.timerId) {
      clearTimeout(task.timerId);
    }

    this.tasks.delete(id);

    logger.info(`task with id ${id} removed from polling manager`);
  }

  public start(): void {
    if (this.active) return;

    this.active = true;

    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    this.tasks.forEach((task) => this.poll(task));
  }

  public stop(): void {
    if (!this.active) return;

    this.active = false;

    this.tasks.forEach((task) => {
      if (!task.timerId) {
        return;
      }

      clearTimeout(task.timerId);

      task.timerId = null;
    });

    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
    );
  }
}
