import React, { createContext, useCallback, useMemo, useState } from "react";

import * as approvalsApi from "~/apps/corporate/apis/approvals.api";
import { getUserFromLocalStorage } from "~/apps/corporate/helpers/user.helper";
import {
  SnackbarProps,
  SnackbarType,
} from "~/apps/shared/components/snackbar/snackbar";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { useSentrySetUser } from "~/apps/shared/hooks/use-sentry-set-user";
import { shouldClearCache } from "~/apps/shared/hooks/with-clear-cache";
import { logger } from "~/apps/shared/utils/logger";

import { usePoll } from "../hooks/use-poll";

interface Actions {
  closeRefreshDialog(): void;
  closeSnackbar: () => Promise<void>;
  loadGeneralData: () => Promise<void>;
  removeExpenseAdvanceFromPendingList: (expenseAdvanceToken: string) => void;
  removeReportFromPendingList: (reportToken: string) => void;
  removeTravelFromPendingList: (tokenToRemove: string) => void;
  setTravelsPending: (pendingTravelTokens: string[]) => void;
  showRefreshDialog(): void;
  showSnackMessage: (
    message: string,
    type: SnackbarType,
    options?: SnackbarProps,
  ) => void;
}

type Selectors = {
  approvalsCount: number;
};

type State = {
  isLoadingClientConfig: boolean;
  isRefreshDialogVisible: boolean;
  pendingAdvancesTokens: string[];
  pendingReportTokens: string[];
  pendingTravelTokens: string[];
  snackbar: {
    message: string;
    open: boolean;
    options?: SnackbarProps;
    type: SnackbarType;
  };
};

type ApplicationContextProps = Actions & Selectors & State;

const FIFTEEN_MINUTE_IN_MILLISECONDS = 15 * 60 * 1000;
const ONE_MINUTE_IN_MILLISECONDS = 1 * 60 * 1000;

const initialState: State = {
  isLoadingClientConfig: false,
  isRefreshDialogVisible: false,
  pendingAdvancesTokens: [],
  pendingReportTokens: [],
  pendingTravelTokens: [],
  snackbar: {
    message: "",
    open: false,
    type: "info",
  },
};

const ApplicationContext = createContext({} as ApplicationContextProps);

export const ApplicationProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  const closeSnackbar = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      snackbar: {
        ...prev.snackbar,
        open: false,
      },
    }));

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        snackbar: {
          message: "",
          open: false,
          options: undefined,
          type: "info",
        },
      }));
    }, 150);
  }, []);

  const showSnackMessage = useCallback(
    (message: string, type: SnackbarType, options?: SnackbarProps) => {
      if (!message) {
        return;
      }

      setState((prev) => ({
        ...prev,
        snackbar: {
          message,
          open: true,
          options,
          type,
        },
      }));
    },
    [],
  );

  const removeTravelFromPendingList = (tokenToRemove: string) => {
    const pendingTokens = state.pendingTravelTokens.filter(
      (token: string) => token !== tokenToRemove,
    );
    setState((prev) => {
      return { ...prev, pendingTravelTokens: pendingTokens };
    });
  };

  const setTravelsPending = (pendingTravelTokens: string[]) => {
    setState((prev) => {
      return { ...prev, pendingTravelTokens };
    });
  };

  const setReportsPending = (pendingReportTokens: string[]) => {
    setState((prev) => {
      return { ...prev, pendingReportTokens };
    });
  };

  const removeReportFromPendingList = (reportToken: string) => {
    const pendingTokens = state.pendingReportTokens.filter(
      (token) => token !== reportToken,
    );
    setReportsPending(pendingTokens);
  };

  const removeExpenseAdvanceFromPendingList = (expenseAdvanceToken: string) => {
    const pendingTokens = state.pendingAdvancesTokens.filter(
      (token) => token !== expenseAdvanceToken,
    );

    setState((prev) => ({
      ...prev,
      pendingAdvancesTokens: pendingTokens,
    }));
  };

  const loadPendingApprovals = () => {
    const user = getUserFromLocalStorage();

    if (!user) {
      return;
    }

    void approvalsApi.getApproverPendingApprovals().then((response) => {
      const { expenseAdvances, expenseReports, travels } = response;

      setState((prev) => ({
        ...prev,
        pendingAdvancesTokens: expenseAdvances,
        pendingReportTokens: expenseReports,
        pendingTravelTokens: travels,
      }));
    });
  };

  usePoll({
    callback: loadPendingApprovals,
    id: "load-pending-approvals",
    interval: FIFTEEN_MINUTE_IN_MILLISECONDS,
  });

  const approvalsCount = useMemo(() => {
    const {
      pendingTravelTokens,
      pendingReportTokens,
      pendingAdvancesTokens,
    } = state;

    return (
      pendingTravelTokens.length +
      pendingReportTokens.length +
      pendingAdvancesTokens.length
    );
  }, [
    state.pendingTravelTokens,
    state.pendingReportTokens,
    state.pendingAdvancesTokens,
  ]);

  const loadGeneralData = useCallback(async () => {
    await Promise.all([loadPendingApprovals()]);
  }, []);

  const showRefreshDialog = useCallback(() => {
    setState((prev) => ({ ...prev, isRefreshDialogVisible: true }));
  }, []);

  const closeRefreshDialog = useCallback(() => {
    setState((prev) => ({ ...prev, isRefreshDialogVisible: false }));
  }, []);

  const checkForAppUpdates = useCallback(async () => {
    try {
      if (state.isRefreshDialogVisible) {
        return null;
      }

      if (await shouldClearCache()) {
        showRefreshDialog();
      }
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error.message);
      } else {
        logger.error(String(error));
      }
    }
  }, [showRefreshDialog, state.isRefreshDialogVisible]);

  usePoll({
    callback: checkForAppUpdates,
    id: "check-for-app-updates",
    interval: ONE_MINUTE_IN_MILLISECONDS,
  });

  useSentrySetUser();

  return (
    <ApplicationContext.Provider
      value={{
        ...state,
        approvalsCount,
        closeRefreshDialog,
        closeSnackbar,
        loadGeneralData,
        removeExpenseAdvanceFromPendingList,
        removeReportFromPendingList,
        removeTravelFromPendingList,
        setTravelsPending,
        showRefreshDialog,
        showSnackMessage,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplication = useContextFactory(
  "ApplicationContext",
  ApplicationContext,
);
