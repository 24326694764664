import { api } from "~/apps/shared/services/api";

import {
  CreateUserLoyaltyProgramResponseBody,
  ListSupplierLoyaltyProgramResponseBody,
  ListUserLoyaltyProgramResponseBody,
  SaveLoyaltyProgramDto,
  UpdateLoyaltyProgramDto,
  UpdateLoyaltyProgramResponseBody,
} from "../dtos/loyalty-program.dto";
import { getAuthorizationHeader } from "../helpers/user.helper";

export async function createLoyaltyProgram(data: SaveLoyaltyProgramDto) {
  return await api
    .request<CreateUserLoyaltyProgramResponseBody>({
      data,
      headers: getAuthorizationHeader(),
      method: "POST",
      url: "/booking/loyalty-programs",
    })
    .then(({ data }) => ({
      token: data.data.token,
      userToken: data.data.user_token,
      number: data.data.number,
      active: data.data.active,
      createdAt: data.data.created_at,
      updatedAt: data.data.updated_at,
      supplierLoyaltyProgramToken: data.data.supplier_loyalty_program_token,
      supplierLoyaltyProgram: {
        token: data.data.supplier_loyalty_program.token,
        name: data.data.supplier_loyalty_program.name,
        active: data.data.supplier_loyalty_program.active,
        resourceType: data.data.supplier_loyalty_program.resource_type,
        allowedSuppliers: data.data.supplier_loyalty_program.allowed_suppliers,
        createdAt: data.data.supplier_loyalty_program.created_at,
        updatedAt: data.data.supplier_loyalty_program.updated_at,
      },
    }));
}

export async function getUserLoyaltyPrograms(
  userToken: string,
  resourceType = "flight",
) {
  return await api
    .request<ListUserLoyaltyProgramResponseBody>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/users/${userToken}/loyalty-programs`,
      params: {
        resource_type: resourceType,
      },
    })
    .then(({ data }) =>
      data.data.map((loyaltyProgram) => ({
        token: loyaltyProgram.token,
        userToken: loyaltyProgram.user_token,
        number: loyaltyProgram.number,
        active: loyaltyProgram.active,
        createdAt: loyaltyProgram.created_at,
        updatedAt: loyaltyProgram.updated_at,
        supplierLoyaltyProgram: {
          token: loyaltyProgram.supplier_loyalty_program.token,
          name: loyaltyProgram.supplier_loyalty_program.name,
          active: loyaltyProgram.supplier_loyalty_program.active,
          resourceType: loyaltyProgram.supplier_loyalty_program.resource_type,
          allowedSuppliers:
            loyaltyProgram.supplier_loyalty_program.allowed_suppliers,
          createdAt: loyaltyProgram.supplier_loyalty_program.created_at,
          updatedAt: loyaltyProgram.supplier_loyalty_program.updated_at,
        },
      })),
    );
}

export async function inactiveLoyaltyProgram(loyaltyProgramToken: string) {
  return await api.request({
    headers: getAuthorizationHeader(),
    method: "PUT",
    url: `/booking/loyalty-programs/${loyaltyProgramToken}/inactive`,
  });
}

export async function updateLoyaltyProgram(
  data: UpdateLoyaltyProgramDto,
  loyaltyProgramToken: string,
) {
  return await api
    .request<UpdateLoyaltyProgramResponseBody>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/loyalty-programs/${loyaltyProgramToken}`,
    })
    .then(({ data }) => ({
      token: data.data.token,
      userToken: data.data.user_token,
      number: data.data.number,
      active: data.data.active,
      createdAt: data.data.created_at,
      updatedAt: data.data.updated_at,
      supplierLoyaltyProgramToken: data.data.supplier_loyalty_program_token,
      supplierLoyaltyProgram: {
        token: data.data.supplier_loyalty_program.token,
        name: data.data.supplier_loyalty_program.name,
        active: data.data.supplier_loyalty_program.active,
        resourceType: data.data.supplier_loyalty_program.resource_type,
        allowedSuppliers: data.data.supplier_loyalty_program.allowed_suppliers,
        createdAt: data.data.supplier_loyalty_program.created_at,
        updatedAt: data.data.supplier_loyalty_program.updated_at,
      },
    }));
}

export async function getSupplierLoyaltyPrograms(resourceType = "flight") {
  return await api
    .request<ListSupplierLoyaltyProgramResponseBody>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: "/booking/supplier-loyalty-programs",
      params: {
        resource_type: resourceType,
      },
    })
    .then(({ data }) =>
      data.data.map((loyaltyProgram) => ({
        token: loyaltyProgram.token,
        name: loyaltyProgram.name,
        active: loyaltyProgram.active,
        resourceType: loyaltyProgram.resource_type,
        allowedSuppliers: loyaltyProgram.allowed_suppliers,
        createdAt: loyaltyProgram.created_at,
        updatedAt: loyaltyProgram.updated_at,
      })),
    );
}
