import { useEffect } from "react";

import { PollingManagerTask } from "~/apps/shared/services/polling-manager";

import { usePollingManager } from "../contexts/polling.context";

export const usePoll = (task: Omit<PollingManagerTask, "timerId">) => {
  const { pollingManager } = usePollingManager();

  useEffect(() => {
    pollingManager.addTask(task);

    return () => {
      pollingManager.remove(task.id);
    };
  }, [pollingManager]);
};
