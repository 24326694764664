import React, { createContext, useCallback, useState } from "react";

import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Document } from "~/apps/shared/models/documents.model";

import { Card } from "../../models/card.model";
import { CompleteLoyaltyProgram } from "../../models/loyalty-program.model";

interface Actions {
  addBoss: () => void;
  addDocument: () => void;
  addLoyaltyProgram: () => void;
  addPaymentMethod: () => void;
  changeView: (view: State["view"]) => void;
  closeRemoveDocumentAlertDialog: () => void;
  closeRemoveLoyaltyProgramAlertDialog: () => void;
  closeRemovePaymentMethodAlertDialog: () => void;
  editBasicInformation: () => void;
  editBoss: () => void;
  editDocument: (document: Document) => void;
  editLoyaltyProgram: (loyaltyProgram: CompleteLoyaltyProgram) => void;
  editPaymentMethod: (paymentMethod: Card) => void;
  editUserPreferences: () => void;
  openRemoveDocumentAlertDialog: (document: Document) => void;
  openRemoveLoyaltyProgramAlertDialog: (
    loyaltyProgram: CompleteLoyaltyProgram,
  ) => void;
  openRemovePaymentMethodAlertDialog: (paymentMethod: Card) => void;
}

type State = {
  isLoadingEditUserProfile: boolean;
  isLoadingRemoveLoyaltyProgram: boolean;
  isRemoveDocumentAlertDialogOpen: boolean;
  isRemoveLoyaltyProgramAlertDialogOpen: boolean;
  isRemovePaymentMethodAlertDialogOpen: boolean;
  selectedDocument: Document | null;
  selectedLoyaltyProgram: CompleteLoyaltyProgram | null;
  selectedPaymentMethod: Card | null;
  view:
    | "default"
    | "edit-basic-information"
    | "edit-user-preferences"
    | "save-user-boss"
    | "save-document"
    | "save-loyalty-program"
    | "save-payment-method";
};

const initialState: State = {
  isLoadingEditUserProfile: false,
  isLoadingRemoveLoyaltyProgram: false,
  isRemoveDocumentAlertDialogOpen: false,
  isRemoveLoyaltyProgramAlertDialogOpen: false,
  isRemovePaymentMethodAlertDialogOpen: false,
  selectedDocument: null,
  selectedLoyaltyProgram: null,
  selectedPaymentMethod: null,
  view: "default",
};

type ContextProps = Actions & State;

const UserProfileDrawerContext = createContext<ContextProps>({
  ...initialState,
  addBoss: () => {
    return;
  },
  addDocument: () => {
    return;
  },
  addLoyaltyProgram: () => {
    return;
  },
  addPaymentMethod: () => {
    return;
  },
  changeView: () => {
    return;
  },
  closeRemoveDocumentAlertDialog: () => {
    return;
  },
  closeRemoveLoyaltyProgramAlertDialog: () => {
    return;
  },
  closeRemovePaymentMethodAlertDialog: () => {
    return;
  },
  editBasicInformation: () => {
    return;
  },
  editBoss: () => {
    return;
  },
  editDocument: () => {
    return;
  },
  editLoyaltyProgram: () => {
    return;
  },
  editPaymentMethod: () => {
    return;
  },
  editUserPreferences: () => {
    return;
  },
  openRemoveDocumentAlertDialog: () => {
    return;
  },
  openRemoveLoyaltyProgramAlertDialog: () => {
    return;
  },
  openRemovePaymentMethodAlertDialog: () => {
    return;
  },
});

export const UserProfileDrawerProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  const changeView = useCallback((view: State["view"]) => {
    setState((prev) => ({
      ...prev,
      view,
    }));
  }, []);

  const addBoss = useCallback(() => {
    setState((prev) => ({
      ...prev,
      view: "save-user-boss",
    }));
  }, []);

  const addDocument = useCallback(() => {
    setState((prev) => ({
      ...prev,
      selectedDocument: null,
      view: "save-document",
    }));
  }, []);

  const addLoyaltyProgram = useCallback(() => {
    setState((prev) => ({
      ...prev,
      selectedLoyaltyProgram: null,
      view: "save-loyalty-program",
    }));
  }, []);

  const addPaymentMethod = useCallback(() => {
    setState((prev) => ({
      ...prev,
      selectedPaymentMethod: null,
      view: "save-payment-method",
    }));
  }, []);

  const closeRemoveDocumentAlertDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isRemoveDocumentAlertDialogOpen: false,
      selectedDocument: null,
    }));
  }, []);

  const closeRemoveLoyaltyProgramAlertDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isRemoveLoyaltyProgramAlertDialogOpen: false,
      selectedLoyaltyProgram: null,
    }));
  }, []);

  const closeRemovePaymentMethodAlertDialog = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isRemovePaymentMethodAlertDialogOpen: false,
      selectedPaymentMethod: null,
    }));
  }, []);

  const editBasicInformation = useCallback(() => {
    setState((prev) => ({
      ...prev,
      view: "edit-basic-information",
    }));
  }, []);

  const editBoss = useCallback(() => {
    setState((prev) => ({
      ...prev,
      view: "save-user-boss",
    }));
  }, []);

  const editDocument = useCallback((document: Document) => {
    setState((prev) => ({
      ...prev,
      selectedDocument: document,
      view: "save-document",
    }));
  }, []);

  const editLoyaltyProgram = useCallback(
    (loyaltyProgram: CompleteLoyaltyProgram) => {
      setState((prev) => ({
        ...prev,
        selectedLoyaltyProgram: loyaltyProgram,
        view: "save-loyalty-program",
      }));
    },
    [],
  );

  const editPaymentMethod = useCallback((paymentMethod: Card) => {
    setState((prev) => ({
      ...prev,
      selectedPaymentMethod: paymentMethod,
      view: "save-payment-method",
    }));
  }, []);

  const editUserPreferences = useCallback(() => {
    setState((prev) => ({
      ...prev,
      view: "edit-user-preferences",
    }));
  }, []);

  const openRemoveDocumentAlertDialog = useCallback((document: Document) => {
    setState((prev) => ({
      ...prev,
      isRemoveDocumentAlertDialogOpen: true,
      selectedDocument: document,
    }));
  }, []);

  const openRemoveLoyaltyProgramAlertDialog = useCallback(
    (loyaltyProgram: CompleteLoyaltyProgram) => {
      setState((prev) => ({
        ...prev,
        isRemoveLoyaltyProgramAlertDialogOpen: true,
        selectedLoyaltyProgram: loyaltyProgram,
      }));
    },
    [],
  );

  const openRemovePaymentMethodAlertDialog = useCallback(
    (paymentMethod: Card) => {
      setState((prev) => ({
        ...prev,
        isRemovePaymentMethodAlertDialogOpen: true,
        selectedPaymentMethod: paymentMethod,
      }));
    },
    [],
  );

  return (
    <UserProfileDrawerContext.Provider
      value={{
        ...state,
        addBoss,
        addDocument,
        addLoyaltyProgram,
        addPaymentMethod,
        changeView,
        closeRemoveDocumentAlertDialog,
        closeRemoveLoyaltyProgramAlertDialog,
        closeRemovePaymentMethodAlertDialog,
        editBasicInformation,
        editBoss,
        editDocument,
        editLoyaltyProgram,
        editPaymentMethod,
        editUserPreferences,
        openRemoveDocumentAlertDialog,
        openRemoveLoyaltyProgramAlertDialog,
        openRemovePaymentMethodAlertDialog,
      }}
    >
      {children}
    </UserProfileDrawerContext.Provider>
  );
};

export const useUserProfileDrawer = useContextFactory(
  "UserProfileDrawerContext",
  UserProfileDrawerContext,
);
