import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";

const JQUERY_SCRIPT_URL = "https://code.jquery.com/jquery-3.6.0.min.js";
const JQUERY_SCRIPT_ID = "jquery-script";

// TODO! Script used for testing purposes.
export const ScriptDownloader: React.FC = () => {
  const { user } = useUser();

  useEffect(() => {
    const script = document.createElement("script");

    script.id = JQUERY_SCRIPT_ID;
    script.async = true;
    script.src = JQUERY_SCRIPT_URL;

    script.onerror = () => {
      if (!user) {
        return;
      }

      Sentry.withScope((scope) => {
        scope.setLevel("error");

        scope.setTag("chat-error", true);

        scope.setContext("data", {
          client: user.getClientName(),
          clientToken: user.getClientToken(),
          email: user.getEmail(),
          userToken: user.getUserToken(),
        });

        scope.setFingerprint(["chat-error", user.getClientToken() || ""]);

        Sentry.captureException("Erro ao baixar o script teste.");
      });
    };

    script.onload = () => {
      console.log("Script teste baixado com sucesso.");
    };

    const headEl = document.getElementsByTagName("head")[0];

    headEl.appendChild(script);
  }, []);

  return null;
};
