import * as Sentry from "@sentry/react";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useLivechat } from "~/apps/corporate/contexts/livechat.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { ChatConfig } from "~/apps/corporate/models/agency.model";
import { UserModel } from "~/apps/corporate/models/user.model";
import { VIP_STATUS_TRANSLTATED } from "~/apps/shared/constants";
import axios from "axios";

import { LIVECHAT_FALLBACK_SCRIPT_URL } from "@constants";

import { Icon } from "../../icon/icon";
import { LiveChatErrorDialog } from "../livechat-dialog-error/livechat-dialog-error";
import { styles } from "./styles";

// When changing the file name, change the constant
const FILE_NAME = "livechat-jivo-chat.tsx";

const buildUserData = (user: UserModel) => {
  const clientName = user.getClientName();
  const clientToken = user.getClientToken();
  const plan = user.getPlan();
  const userToken = user.getUserToken();
  const vipStatus = user.getUserVipStatus();

  const translatedVipStatus = vipStatus
    ? VIP_STATUS_TRANSLTATED[vipStatus]
    : undefined;

  const dataArray = [];

  dataArray.push({ title: "ID do usuário", content: userToken });
  dataArray.push({ title: "ID do cliente", content: clientToken });
  dataArray.push({ title: "Nome do cliente", content: clientName });
  dataArray.push({ title: "Plano", content: plan });
  dataArray.push({
    title: "Prioridade de atendimento",
    content: translatedVipStatus,
  });

  return dataArray;
};

type Props = {
  chatConfig: ChatConfig;
};

export const LivechatJivoChat: React.FC<Props> = ({ chatConfig }) => {
  const {
    isLoaded,
    setIsLoaded,
    setErrorOnDownloadScript,
    errorOnDownloadScript,
  } = useLivechat();
  const { user } = useUser();

  const [isChatButtonVisible, setChatButtonVisibility] = useState(true);
  const [dialogErrorIsOpen, setDialogErrorIsOpen] = useState(false);

  const apiRef = useRef<any | null>(window.jivo_api || null);
  const jivoRef = useRef<HTMLElement | null>(
    document.getElementById("jcont") || null,
  );

  const isLoggedIn = useMemo(() => !!user, [user]);

  const chatUserLogin = () => {
    void setUserInfo();

    if (!jivoRef.current) {
      return;
    }

    jivoRef.current.style.visibility = "hidden";
  };

  const chatUserLogout = () => {
    if (!jivoRef.current) {
      return;
    }

    jivoRef.current.style.visibility = "hidden";
  };

  const handleOpenChat = () => {
    if (!isChatButtonVisible) {
      return;
    }

    apiRef.current?.open();
  };

  const setUserInfo = async () => {
    if (!user) {
      return;
    }

    const email = user.getEmail();
    const phone = user.getPhone();
    const fullName = user.getFullName();
    const userToken = user.getUserToken();

    const chatApi = apiRef.current || window.jivo_api;

    if (!chatApi) {
      return;
    }

    chatApi.setContactInfo({
      email,
      name: fullName,
      phone,
    });

    const userData = buildUserData(user);

    chatApi.setCustomData(userData);

    await chatApi.setUserToken(userToken);
  };

  window.onerror = (_message, source) => {
    if (source?.includes(FILE_NAME)) {
      if (!user) {
        return;
      }

      Sentry.withScope((scope) => {
        scope.setLevel("error");

        scope.setTag("chat-error", true);

        scope.setContext("data", {
          client: user.getClientName(),
          clientToken: user.getClientToken(),
          email: user.getEmail(),
          userToken: user.getUserToken(),
        });

        scope.setFingerprint(["chat-error", user.getClientToken() || ""]);

        Sentry.captureException("Erro de execução do chat.");
      });
    }
  };

  const checkScript = async (url: string) => {
    try {
      const response = await axios.get(url);
      console.log(`Success to fetch script, url: ${url}`, response);
    } catch (error: any) {
      const errorDetails = JSON.stringify(
        error,
        Object.getOwnPropertyNames(error),
      );

      console.error(`Failed to fetch script, url: ${url}`, errorDetails);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.id = `${chatConfig.provider}-script`;
    script.async = true;
    script.src = `${chatConfig.url}/${chatConfig.identifier}`;

    script.onerror = (error) => {
      if (!user) {
        return;
      }

      Sentry.withScope((scope) => {
        scope.setLevel("error");

        scope.setTag("chat-error", true);

        scope.setContext("data", {
          client: user.getClientName(),
          clientToken: user.getClientToken(),
          email: user.getEmail(),
          userToken: user.getUserToken(),
          chatConfig: chatConfig,
          url: script.src,
        });

        scope.setFingerprint(["chat-error", user.getClientToken() || ""]);

        void checkScript(script.src);
        Sentry.captureException(error);
      });

      const fallbackScript = document.createElement("script");
      fallbackScript.id = `${chatConfig.provider}-script-fallback`;
      fallbackScript.async = true;
      fallbackScript.src = `${LIVECHAT_FALLBACK_SCRIPT_URL}${chatConfig.identifier}`;

      fallbackScript.onerror = (error) => {
        setErrorOnDownloadScript(true);

        Sentry.withScope((scope) => {
          scope.setLevel("error");

          scope.setTag("chat-error", true);

          scope.setContext("data", {
            client: user.getClientName(),
            clientToken: user.getClientToken(),
            email: user.getEmail(),
            userToken: user.getUserToken(),
            chatConfig: chatConfig,
            url: fallbackScript.src,
          });

          scope.setFingerprint(["chat-error", user.getClientToken() || ""]);

          void checkScript(fallbackScript.src);
          Sentry.captureException(error);
        });
      };

      const headEl = document.getElementsByTagName("head")[0];
      headEl.appendChild(fallbackScript);
    };

    // on script load
    // does not work on mobile
    window.jivo_onLoadCallback = () => {
      apiRef.current = window.jivo_api;
      jivoRef.current = document.getElementById("jcont");

      if (jivoRef.current) {
        jivoRef.current.style.visibility = "hidden";
      }

      setIsLoaded();
    };

    window.jivo_onOpen = () => {
      setChatButtonVisibility(false);

      void setUserInfo();
    };

    window.jivo_onClose = () => setChatButtonVisibility(true);

    const headEl = document.getElementsByTagName("head")[0];

    headEl.appendChild(script);
  }, [chatConfig, setIsLoaded, setUserInfo]);

  useEffect(() => {
    const isApiLoaded = isLoaded && !!apiRef.current;

    if (isApiLoaded && isLoggedIn) {
      chatUserLogin();
    }

    if (isApiLoaded && !isLoggedIn) {
      chatUserLogout();
    }
  }, [apiRef.current, isLoaded, isLoggedIn]);

  useEffect(() => {
    if (jivoRef.current && isChatButtonVisible) {
      jivoRef.current.style.visibility = "hidden";
    } else if (jivoRef.current && !isChatButtonVisible) {
      jivoRef.current.style.visibility = "visible";
    }
  }, [isChatButtonVisible, jivoRef.current]);

  const canShowButton = useMemo(
    () => isChatButtonVisible && isLoaded && !!isLoggedIn,
    [isChatButtonVisible, isLoaded, isLoggedIn],
  );

  if (errorOnDownloadScript) {
    return (
      <>
        <LiveChatErrorDialog
          open={dialogErrorIsOpen}
          handleClose={() => setDialogErrorIsOpen(false)}
        />
        <div css={styles.root}>
          <button
            css={styles.button({ isError: true })}
            onClick={() => setDialogErrorIsOpen(true)}
          >
            <Icon use="exclamation-triangle" />
          </button>
        </div>
      </>
    );
  }

  if (!canShowButton) {
    return null;
  }

  return (
    <div css={styles.root}>
      <button css={styles.button({ isError: false })} onClick={handleOpenChat}>
        <Icon use="chat-bubble-bottom-center-text" />
      </button>
    </div>
  );
};
