import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    loading: "Loading agencies...",
    error: "Error fetching car agencies",
    empty: "No agencies found",
  },
  [LanguageCode.PT_BR]: {
    loading: "Carregando agências...",
    error: "Erro ao carregar agências",
    empty: "Nenhuma agência encontrada",
  },
};

export const useCarAgenciesSelectTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription = transcriptions[languageCode];

  return { transcription };
};
