import React from "react";

import { WarningRounded as WarningIcon } from "@material-ui/icons";
import css from "@styled-system/css";
import { Icon } from "~/apps/shared/components/icon/icon";

const styles = {
  icon: css({
    color: "#1c72f4",
  }),
  root: css({
    alignItems: "center",
    backgroundColor: "#d9edff",
    border: "1px solid",
    borderColor: "#59b2ff",
    borderRadius: "0.375rem",
    display: "flex",
    gap: "0.75rem",
    padding: "0.75rem 1rem",
    width: "fit-content",
  }),
  text: css({
    fontSize: "0.875rem",
    lineHeight: "1.125rem",
  }),
};

export type WarningMessageProps = {
  message: string | React.ReactNode;
  icon?: "warning" | "info";
};

export const WarningMessage: React.FC<WarningMessageProps> = ({
  message,
  icon = "warning",
}) => {
  const getIcon = () => {
    if (icon === "warning") {
      return <WarningIcon css={styles.icon} />;
    }

    return <Icon size={16} css={styles.icon} use="info-outline" />;
  };

  return (
    <div css={styles.root}>
      {getIcon()}
      <span css={styles.text}>{message}</span>
    </div>
  );
};
