import React, { useMemo } from "react";

import { useLoyaltyPrograms } from "~/apps/corporate/contexts/loyalty-program.context";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import { Select } from "~/apps/shared/components/select/select";

import { Button } from "@toolkit/v2";

import { useUserProfileDrawer } from "../../user-profile-drawer.context";
import {
  saveLoyaltyProgramSchema,
  SaveLoyaltyProgramSchema,
} from "./save-loyalty-program.schema";
import { useUserProfileDrawerSaveLoyaltyProgramTranscription } from "./save-loyalty-program.transcription";
import { styles } from "./styles";

type Props = {
  back: () => void;
};

export const UserProfileDrawerSaveLoyaltyProgram: React.FC<Props> = ({
  back,
}) => {
  const {
    transcription,
  } = useUserProfileDrawerSaveLoyaltyProgramTranscription();
  const { saveLoyaltyProgram, supplierLoyaltyPrograms } = useLoyaltyPrograms();
  const { selectedLoyaltyProgram } = useUserProfileDrawer();

  const loyaltyProgramsOptions = useMemo(() => {
    return supplierLoyaltyPrograms.map((program) => ({
      label: program.name,
      value: program.token,
    }));
  }, [supplierLoyaltyPrograms]);

  const form = useForm<SaveLoyaltyProgramSchema>({
    defaultValues: {
      number: selectedLoyaltyProgram ? selectedLoyaltyProgram.number : null,
      supplierLoyaltyProgram: selectedLoyaltyProgram
        ? selectedLoyaltyProgram.supplierLoyaltyProgram.token
        : null,
    },
    onSubmit: async () => {
      const values = form.getValues();

      const sucess = await saveLoyaltyProgram({
        token: selectedLoyaltyProgram?.token,
        number: values.number,
        supplierLoyaltyProgramToken: values.supplierLoyaltyProgram,
      });

      if (!sucess) {
        return;
      }

      back();
    },
    schema: saveLoyaltyProgramSchema,
  });

  const { supplierLoyaltyProgram } = form.watch(["supplierLoyaltyProgram"]);
  const selectedSupplierLoyaltyProgram = useMemo(() => {
    return loyaltyProgramsOptions.find(
      (option) => option.value === supplierLoyaltyProgram,
    );
  }, [loyaltyProgramsOptions, supplierLoyaltyProgram]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.body.root}>
        <div css={styles.body.select.root}>
          <span css={styles.body.select.label}>
            {transcription.fields.program.label}
          </span>
          <Select
            id="supplierLoyaltyProgram"
            name="supplierLoyaltyProgram"
            onChange={({ value }) => {
              form.setValue("supplierLoyaltyProgram", value);
            }}
            options={loyaltyProgramsOptions}
            placeholder={transcription.fields.program.placeholder}
            useSetDefaultValueIfOnlyOneOption
            value={selectedSupplierLoyaltyProgram}
          />
          <InputErrorMessage>
            {form.errors["supplierLoyaltyProgram"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>
            {transcription.fields.number.label}
          </span>
          <Form.Field
            name="number"
            render={({ onChange, value }) => (
              <Input
                css={styles.body.input.input}
                onChange={onChange}
                placeholder={transcription.fields.number.placeholder}
                value={value}
              />
            )}
          />
          <InputErrorMessage>
            {form.errors["number"]?.message}
          </InputErrorMessage>
        </div>
      </div>
      <div css={styles.footer.root}>
        <Button
          disabled={form.formState.isSubmitting}
          fill="outlined"
          onClick={() => {
            back();
          }}
          type="button"
        >
          {transcription.buttons.cancel}
        </Button>
        <Button disabled={form.formState.isSubmitting} variant="pink">
          {transcription.buttons.save}
        </Button>
      </div>
    </Form>
  );
};
