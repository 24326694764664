import React, { createContext, useEffect } from "react";

import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { PollingManager } from "~/apps/shared/services/polling-manager";

const globalPollingManager = new PollingManager();

type ContextProps = {
  pollingManager: PollingManager;
};

const PollingContext = createContext<ContextProps>({
  pollingManager: globalPollingManager,
});

export const PollingProvider: React.FC = ({ children }) => {
  useEffect(() => {
    globalPollingManager.start();
    return () => {
      globalPollingManager.stop();
    };
  }, []);

  return (
    <PollingContext.Provider
      value={{
        pollingManager: globalPollingManager,
      }}
    >
      {children}
    </PollingContext.Provider>
  );
};

export const usePollingManager = useContextFactory(
  "PollingContext",
  PollingContext,
);
