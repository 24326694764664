import * as Sentry from "@sentry/react";

import {
  Error as CustomError,
  ErrorWithTranscription as CustomErrorWithTranscription,
} from "~/apps/shared/types/index";
import { AxiosError } from "axios";
import { APP_ERROR_CODES } from "sm-types/errors";

import { ERROR } from "../constants/errors";
import { logger } from "./logger";

class AxiosErrorFormatter {
  constructor(private readonly fallbackError: CustomError = ERROR.UNEXPECTED) {}

  public format(error: AxiosError) {
    const { response } = error;

    if (!response) {
      return this.handleConnectionError();
    }

    const { data, status } = response;

    if (data) {
      const { type, usecase } = data;

      if (type && usecase) {
        const messageFromAppErrorCodes = getMessageFromAppErrorCodes(
          type,
          usecase,
        );

        if (messageFromAppErrorCodes) {
          return {
            ...this.fallbackError,
            description: messageFromAppErrorCodes,
          };
        }
      }

      if (data.message) {
        return {
          ...this.fallbackError,
          description: data.message,
        };
      }
    }

    if (status === 400) {
      return this.handleBadRequestError();
    }

    if (status === 403) {
      return this.handleForbiddenError();
    }

    if (status === 403) {
      return this.handleConflictError();
    }

    if (status === 500) {
      return this.handleInternalServerError();
    }

    return {
      ...this.fallbackError,
      data,
    };
  }

  private handleBadRequestError() {
    return ERROR.GENERAL_BAD_REQUEST_ERROR;
  }

  private handleConflictError() {
    return ERROR.GENERAL_CONFLICT_ERROR;
  }

  private handleConnectionError() {
    return ERROR.GENERAL_CONNECTION_ERROR;
  }

  private handleForbiddenError() {
    return ERROR.GENERAL_FORBIDDEN_ERROR;
  }

  private handleInternalServerError() {
    return this.fallbackError;
  }
}

type FormatServiceErrorParams = {
  error?: any;
  fallback?: CustomError;
};

export const formatServiceError = <T = any>(
  params?: FormatServiceErrorParams,
): CustomError<T> => {
  if (!params) {
    return ERROR.UNEXPECTED;
  }

  const { error, fallback } = params;

  const serviceErrorFormatter = new ServiceErrorFormatter(fallback);

  return serviceErrorFormatter.format(error);
};

type FormatServiceErrorWithTranscriptionParams = {
  error?: any;
  fallback?: CustomErrorWithTranscription;
};

export const formatServiceErrorWithTranscription = <T = any>(
  params?: FormatServiceErrorWithTranscriptionParams,
): CustomError<T> | CustomErrorWithTranscription<T> => {
  if (!params) {
    return ERROR.UNEXPECTED;
  }

  const { error, fallback } = params;

  const serviceErrorFormatter = new ServiceErrorFormatter(
    (fallback as unknown) as CustomError,
  );

  return serviceErrorFormatter.format(error);
};

const getMessageFromAppErrorCodes = (type: string, usecase: string) => {
  return (APP_ERROR_CODES[usecase as keyof typeof APP_ERROR_CODES] as any)?.[
    type
  ]?.messageLangMap?.["PT-BR"];
};

const isAxiosError = (error: any): error is AxiosError => {
  return error.isAxiosError === true;
};

const isAlreadyFormattedError = (error: any): error is CustomError => {
  return (
    typeof error === "object" && "description" in error && "title" in error
  );
};

class ServiceErrorFormatter {
  constructor(private readonly fallbackError: CustomError = ERROR.UNEXPECTED) {}

  public format(error: any) {
    if (!error) {
      return this.fallbackError;
    }

    if (isAlreadyFormattedError(error)) {
      return this.handleAlreadyFormattedError(error);
    }

    if (isAxiosError(error)) {
      return this.handleAxiosError(error);
    }

    return this.handleUnexpectedError(error);
  }

  private handleAlreadyFormattedError(error: CustomError) {
    return error;
  }

  private handleAxiosError(error: AxiosError) {
    const axiosErrorFormatter = new AxiosErrorFormatter(this.fallbackError);

    return axiosErrorFormatter.format(error);
  }

  private handleUnexpectedError(error: any) {
    logger.error(error);
    this.notifySentryAboutUnexpectedError(error);

    return ERROR.UNEXPECTED;
  }

  private notifySentryAboutUnexpectedError(error: any) {
    Sentry.withScope((scope) => {
      scope.setTag("unexpected", true);

      Sentry.captureException(error);
    });
  }
}
