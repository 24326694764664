import { AxiosInstance } from "axios";

export class ApiFullfiledInterceptor {
  public intercept(api: AxiosInstance): void {
    api.interceptors.response.use((response) => {
      const { data, status } = response;

      if ((data === null || data === undefined) && status !== 204) {
        return Promise.reject("empty response");
      }

      return response;
    }, undefined);
  }
}
