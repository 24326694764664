import React from "react";
import Skeleton from "react-loading-skeleton";

import css from "@styled-system/css";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/apps/shared/components/dialog/dialog";

import { theme } from "@skin/v2";

import { Button } from "@toolkit/v2";

import { WarningMessage } from "@components/shared/warning-message/warning-message";

import { useLiveChatDialogErrorTranscription } from "./livechat-dialog-error.transcription";

const styles = {
  description: css({
    color: theme.colors.gray[300],
    lineHeight: "1.25rem",
  }),
  ul: {
    root: css({
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      listStyle: "disc",
      listStyleType: "upper-roman",
      listStylePosition: "inside",
      margin: "1rem 0",
      padding: "1rem",
      backgroundColor: theme.colors.background.gray,
      borderRadius: "0.25rem",
      fontSize: "0.875rem",
    }),
    li: css({
      lineHeight: "1.25rem",
      color: theme.colors.gray[700],
    }),
  },
  email: css({
    color: "primary",
    textDecoration: "underline",
  }),
};

export type LiveChatErrorDialogProps = {
  open: boolean;
  handleClose: () => void;
};

export const LiveChatErrorDialog: React.FC<LiveChatErrorDialogProps> = (
  props,
) => {
  const { transcription } = useLiveChatDialogErrorTranscription();

  const { clientConfig, isLoading: isLoadingClientConfig } = useClientConfig();

  return (
    <Dialog {...props}>
      <DialogHeader
        variant="pink"
        icon="exclamation-triangle"
        onClose={props.handleClose}
      />
      <DialogTitle variant="pink">{transcription.title}</DialogTitle>
      <DialogContent>
        <p css={styles.description}>{transcription.description}</p>
        <ul css={styles.ul.root}>
          {transcription.bulletPoints.map((text) => (
            <li css={styles.ul.li} key={text}>
              {text}
            </li>
          ))}
        </ul>

        <WarningMessage
          icon="info"
          message={
            <p>
              {transcription.pleaseContactUs}{" "}
              {isLoadingClientConfig || !clientConfig ? (
                <Skeleton width={100} style={{ marginTop: "0.25rem" }} />
              ) : (
                <span style={{ fontWeight: 600 }}>
                  {clientConfig.getAgencyEmail()}
                </span>
              )}
            </p>
          }
        />
      </DialogContent>
      <DialogFooter>
        <Button onClick={() => props.handleClose()} variant="pink">
          {transcription.okButton}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
