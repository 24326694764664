import React, { useEffect, useState } from "react";
import ReactSelect, {
  Props,
  GroupBase,
  PlaceholderProps,
  components,
  InputProps,
  OptionProps,
} from "react-select";

import { listCarAgencies } from "~/apps/corporate/apis/car.api";
import { Icon } from "~/apps/shared/components/icon/icon";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { CarSupplier } from "sm-types/sm-car-rentals";

import { theme } from "@skin/v2";

import { useApplicationContext } from "~/Context";

import { useCarAgenciesSelectTranscription } from "./car-agencies-select.transcription";

const { Placeholder, Input, Option } = components;

const SUPPLIER_LABELS = {
  [CarSupplier.LOCALIZA]: "Localiza",
  [CarSupplier.MOVIDA]: "Movida",
};

const SUPPLIER_COLORS_BY_LABEL = {
  [SUPPLIER_LABELS.localiza]: "#01602A",
  [SUPPLIER_LABELS.movida]: "#FF5001",
};

export type CarAgenciesSelectProps = {
  longitude?: number;
  latitude?: number;
  styleType?: "rounded" | "square";
} & Props<Option, false, GroupBase<Option>>;

type Option = {
  label: string;
  value: string;
  isAirport: boolean;
  supplierLabel: string;
};

export const CarAgenciesSelect: React.FC<CarAgenciesSelectProps> = ({
  styleType = "rounded",
  longitude,
  latitude,
  ...props
}) => {
  const { transcription } = useCarAgenciesSelectTranscription();

  const { showSnackMessage } = useApplicationContext();

  const [options, setOptions] = useState<
    {
      label: string;
      options: Option[];
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const loadOptions = async () => {
    try {
      setIsLoading(true);

      if (!latitude || !longitude) {
        setIsDisabled(true);
        return;
      } else {
        setIsDisabled(false);
      }

      const response = await listCarAgencies(latitude, longitude);

      const groupedOptions = Object.keys(response).map((key) => ({
        label: SUPPLIER_LABELS[key as keyof typeof SUPPLIER_LABELS],
        options:
          response[key as keyof typeof response]?.map((agency) => ({
            label: agency.name,
            value: agency.code,
            isAirport: agency.airport,
            supplierLabel: SUPPLIER_LABELS[key as keyof typeof SUPPLIER_LABELS],
          })) ?? [],
      }));

      groupedOptions.sort((a, b) => {
        if (a.label === SUPPLIER_LABELS[CarSupplier.LOCALIZA]) return -1;
        if (b.label === SUPPLIER_LABELS[CarSupplier.LOCALIZA]) return 1;
        return 0;
      });

      setOptions(groupedOptions);
    } catch (error) {
      showSnackMessage(transcription.error, ALERT_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const customPlaceholder = (props: PlaceholderProps<Option>) => (
    <Placeholder {...props}>
      <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <Icon
          use="building"
          size={22}
          style={{ color: theme.colors.gray[700] }}
        />
        <span style={{ color: theme.colors.gray[100], marginLeft: "0.25rem" }}>
          {props.selectProps.placeholder}
        </span>
      </div>
    </Placeholder>
  );

  const customInput = (props: InputProps<Option>) => (
    <>
      <Icon
        use="building"
        size={22}
        style={{
          color: theme.colors.gray[700],
          marginLeft: "0.125rem",
          marginTop: "-2rem",
        }}
      />
      <Input {...props} />
    </>
  );

  const customOption = (props: OptionProps<Option>) => (
    <Option {...props}>
      <div style={{ display: "flex", alignItems: "center", gap: "0.625rem" }}>
        <Icon
          use={props.data.isAirport ? "airplane" : "building"}
          size={22}
          style={{
            color:
              SUPPLIER_COLORS_BY_LABEL[
                props.data
                  .supplierLabel as keyof typeof SUPPLIER_COLORS_BY_LABEL
              ],
          }}
        />

        <span style={{ color: theme.colors.gray[400] }}>
          {props.data.label}
        </span>
      </div>
    </Option>
  );

  useEffect(() => {
    void loadOptions();
  }, [latitude, longitude]);

  return (
    <ReactSelect
      options={options}
      components={{
        Placeholder: customPlaceholder,
        Input: customInput,
        Option: customOption,
      }}
      loadingMessage={() => transcription.loading}
      noOptionsMessage={() => transcription.empty}
      isLoading={isLoading}
      styles={{
        container: (base) => ({
          ...base,
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          borderRadius: styleType === "rounded" ? "2rem" : "0.5rem",
          borderColor: theme.colors.gray[100],
          padding:
            styleType === "rounded" ? "0.625rem 0.25rem" : "0.25rem 0.25rem",
          cursor: "pointer",
          boxShadow: "none",
          "&:focus-within": {
            borderColor: theme.colors.gray[400],
          },
          "&:hover": {
            borderColor: theme.colors.gray[400],
          },
        }),
        input: (base) => ({
          ...base,
          padding: "0.25rem 2rem",
        }),
        option: (base, state) => ({
          ...base,
          padding: "0.625rem 1rem",
          fontSize: "0.875rem",
          cursor: "pointer",
          color: state.isSelected
            ? theme.colors.gray[700]
            : theme.colors.gray[400],
          backgroundColor: state.isSelected
            ? theme.colors.background.gray
            : "transparent",
          borderLeft: `1px solid ${
            SUPPLIER_COLORS_BY_LABEL[
              state.data.supplierLabel as keyof typeof SUPPLIER_COLORS_BY_LABEL
            ]
          }`,
          "&:hover": {
            backgroundColor: theme.colors.background.gray,
            color: theme.colors.gray[700],
          },
        }),
        singleValue: (base) => ({
          ...base,
          padding: "0.25rem 2rem",
        }),
        groupHeading: (base, { data }) => ({
          ...base,
          padding: "0.875rem 1rem",
          paddingLeft: "1rem",
          fontSize: "1rem",
          color:
            SUPPLIER_COLORS_BY_LABEL[
              data.label as keyof typeof SUPPLIER_COLORS_BY_LABEL
            ],
          borderLeft: `1px solid ${
            SUPPLIER_COLORS_BY_LABEL[
              data.label as keyof typeof SUPPLIER_COLORS_BY_LABEL
            ]
          }`,
          marginBottom: "-0.125rem",
          textTransform: "none",
        }),
        group: (base) => ({
          ...base,
          padding: 0,
        }),
        menuList: (base) => ({
          ...base,
          padding: 0,
        }),
      }}
      {...props}
      placeholder={isLoading ? transcription.loading : props.placeholder}
      isDisabled={isLoading || isDisabled}
    />
  );
};
