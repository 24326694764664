import React, { useCallback, useState } from "react";

import { useLivechat } from "~/apps/corporate/contexts/livechat.context";
import { LiveChatErrorDialog } from "~/apps/shared/components/livechat/livechat-dialog-error/livechat-dialog-error";

import { useNavBarDesktopHelpButtonTranscription } from "./help-button.transcription";
import { styles } from "./styles";

export const NavBarDesktopHelpButton: React.FC = () => {
  const { transcription } = useNavBarDesktopHelpButtonTranscription();

  const { openLivechat, errorOnDownloadScript } = useLivechat();

  const [dialogErrorIsOpen, setDialogErrorIsOpen] = useState(false);

  const handleOpenLivechat = useCallback(() => {
    if (errorOnDownloadScript) {
      setDialogErrorIsOpen(true);
      return;
    }

    openLivechat();
  }, [errorOnDownloadScript, openLivechat]);

  return (
    <>
      <LiveChatErrorDialog
        open={dialogErrorIsOpen}
        handleClose={() => setDialogErrorIsOpen(false)}
      />
      <button css={styles.root} onClick={handleOpenLivechat}>
        {transcription.title}
      </button>
    </>
  );
};
