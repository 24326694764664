import * as Sentry from "@sentry/react";

import { navigate } from "@reach/router";
import { getAuthorizationHeader } from "~/apps/corporate/helpers/user.helper";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

import { logger } from "../utils/logger";
import { ApiRejectedInterceptorHelper } from "./api-rejected-interceptor.helper";

export class ApiRejectedInterceptor {
  private readonly apiRejectedInterceptorHelper: ApiRejectedInterceptorHelper = new ApiRejectedInterceptorHelper();

  private readonly refreshAccessTokenNavigatorLockKey = "handle_token_refresh";
  private readonly refreshAccessTokenTimeoutInMilliseconds = 10 * 1000;

  private failedPromisesQueue: PromiseConstructor[] = [];
  private isRefreshingAccessToken = false;

  public intercept(api: AxiosInstance): void {
    api.interceptors.response.use(undefined, async (error) => {
      if (this.shouldInterceptInternalServerError(error)) {
        this.notifySentryAboutInternalServerError(error);
      }

      if (this.shouldInterceptUnauthorizedError(error)) {
        const originalRequest = error.config;

        if (this.isRefreshingAccessToken) {
          return this.handleUnauthorizedErrorWhileRefreshing(
            api,
            error,
            originalRequest,
          );
        }

        originalRequest._retry = true;

        return this.handleUnauthorizedError(api, originalRequest);
      }

      return Promise.reject(error);
    });
  }

  private async handleUnauthorizedError(
    api: AxiosInstance,
    originalRequest: any,
  ): Promise<AxiosResponse> {
    this.isRefreshingAccessToken = true;

    return new Promise((resolve, reject) => {
      this.handleRefreshAccessToken()
        .then((accessToken) => {
          this.apiRejectedInterceptorHelper.attachTokenToRequest(
            originalRequest,
            accessToken,
          );

          this.apiRejectedInterceptorHelper.setAccessToken(accessToken);

          this.processFailedPromisesQueue(null, accessToken);

          resolve(api.request(originalRequest));
        })
        .catch((error) => {
          this.processFailedPromisesQueue(error, null);

          this.handleRefreshAccessTokenError(error, reject);
        })
        .finally(() => {
          this.isRefreshingAccessToken = false;
        });
    });
  }

  private async handleUnauthorizedErrorWhileRefreshing(
    api: AxiosInstance,
    error: AxiosError,
    originalRequest: any,
  ): Promise<AxiosResponse> {
    return new Promise<string>((resolve, reject) => {
      this.failedPromisesQueue.push({
        resolve,
        reject,
      } as PromiseConstructor);
    })
      .then((accessToken) => {
        originalRequest._queued = true;

        this.apiRejectedInterceptorHelper.attachTokenToRequest(
          originalRequest,
          accessToken,
        );

        return api.request(originalRequest);
      })
      .catch((_error) => {
        logger.error(_error);

        return Promise.reject(error);
      });
  }

  private async handleRefreshAccessToken(): Promise<string> {
    if ("locks" in navigator) {
      return new Promise((resolve, reject) => {
        (navigator as any).locks.request(
          this.refreshAccessTokenNavigatorLockKey,
          async () => {
            await this.refreshAccessToken().then(resolve).catch(reject);
          },
        );
      });
    }

    return this.refreshAccessToken();
  }

  private handleRefreshAccessTokenError(
    error: AxiosError,
    reject: (error?: Error) => void,
  ): void {
    if (this.isRefreshAccessTokenErrorUnexpected(error)) {
      this.notifySentryAboutUnexpectedRefreshAccessTokenError(error);
    }

    if (this.shouldUnauthenticate(error)) {
      this.unauthenticate(error);
    }

    reject(error);
  }

  private isRefreshAccessTokenErrorUnexpected(error: AxiosError): boolean {
    if (
      this.apiRejectedInterceptorHelper.hasRefreshAccessTokenTimedOut(error) ||
      this.apiRejectedInterceptorHelper.hasRefreshAccessTokenThrownMismatch(
        error,
      )
    ) {
      return true;
    }

    return false;
  }

  private notifySentryAboutInternalServerError(error: AxiosError): void {
    Sentry.withScope((scope) => {
      scope.setTag("internal", true);

      const { config, response } = error;

      scope.setContext("axios", {
        data: response?.data,
        headers: config.headers,
        method: config.method,
        status: response?.status,
        url: config.url,
      });

      scope.setFingerprint([
        "axios",
        `${config.method}`,
        `${config.url}`,
        `${response ? response.status : "unknown"}`,
      ]);

      Sentry.captureException(error);
    });
  }

  private notifySentryAboutUnexpectedRefreshAccessTokenError(
    error: AxiosError,
  ): void {
    Sentry.withScope((scope) => {
      scope.setTag("unauthorized", true);

      Sentry.captureException(error);
    });
  }

  private processFailedPromisesQueue(
    error: AxiosError | null,
    token: unknown = null,
  ): void {
    this.failedPromisesQueue.forEach((promise) => {
      if (error) {
        void promise.reject(error);
      } else {
        void promise.resolve(token);
      }
    });

    this.failedPromisesQueue = [];
  }

  private async refreshAccessToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      return axios
        .post(`${BASE_URL}/auth/refresh`, undefined, {
          headers: getAuthorizationHeader(),
          timeout: this.refreshAccessTokenTimeoutInMilliseconds,
          withCredentials: true,
        })
        .then(({ data }) => {
          resolve(data.data.access_token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private shouldInterceptInternalServerError(error: AxiosError): boolean {
    return this.apiRejectedInterceptorHelper.isInternalServerError(error);
  }

  private shouldInterceptUnauthorizedError(error: any): boolean {
    return (
      error.config._queued ||
      error.config._retry ||
      this.apiRejectedInterceptorHelper.isUnauthorized(error)
    );
  }

  private shouldUnauthenticate(error: AxiosError): boolean {
    if (
      this.apiRejectedInterceptorHelper.isRefreshAccessTokenDuplicate(error)
    ) {
      return false;
    }

    return this.apiRejectedInterceptorHelper.isForbidden(error);
  }

  private unauthenticate(error: AxiosError): void {
    if (!error.response) {
      return;
    }

    const { data } = error.response;

    navigate("/login", { state: { err: data?.message } });

    this.apiRejectedInterceptorHelper.clearAccessToken();

    Sentry.setUser(null);
  }
}
