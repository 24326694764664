import * as yup from "yup";

export const saveLoyaltyProgramSchema = yup.object().shape({
  number: yup.string().required("Esse campo é obrigatório."),
  supplierLoyaltyProgram: yup.string().required("Esse campo é obrigatório."),
});

export type SaveLoyaltyProgramSchema = yup.InferType<
  typeof saveLoyaltyProgramSchema
>;
