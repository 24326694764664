import axios, { AxiosInstance } from "axios";

import { ApiFullfiledInterceptor } from "./api-fulfiled-interceptor";
import { ApiRejectedInterceptor } from "./api-rejected-interceptor";

class Api {
  public api: AxiosInstance;

  constructor() {
    const api = axios.create({
      baseURL: BASE_URL,
    });

    this.interceptOnFulfilled(api);
    this.interceptOnRejected(api);

    this.api = api;
  }

  private interceptOnFulfilled(api: AxiosInstance): void {
    new ApiFullfiledInterceptor().intercept(api);
  }

  private interceptOnRejected(api: AxiosInstance): void {
    new ApiRejectedInterceptor().intercept(api);
  }
}

export const { api } = new Api();
