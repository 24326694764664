import React, { useCallback, useEffect, useMemo } from "react";
import Cards from "react-credit-cards";
import { useQueryClient } from "react-query";

import { useCards } from "~/apps/corporate/contexts/cards.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { RegisterSettingsServiceStatus } from "~/apps/corporate/dtos/client.dto";
import { ClientConfigModel } from "~/apps/corporate/models/client.model";
import { invalidateBillingProfilePayableCardsForTravel } from "~/apps/corporate/pages/travels/payment/payment-content/payment-decision/payment-payment.hooks";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { InputMasked } from "~/apps/shared/components/input-masked/input-masked";
import { Input } from "~/apps/shared/components/input/input";
import {
  CARD_ISSUERS,
  CREDIT_CARD_MASKS,
} from "~/apps/shared/constants/credit-card.constant";
import { logger } from "~/apps/shared/utils/logger";
import { removeBlankSpaces } from "~/apps/shared/utils/remove-blank-spaces";

import { Button } from "@toolkit/v2";

import {
  createPaymentMethodSchema,
  CreatePaymentMethodSchema,
} from "./create-payment-method.schema";
import { useCreatePaymentMethodTranscription } from "./create-payment-method.transcription";
import { styles } from "./styles";

type Transcription = ReturnType<
  typeof useCreatePaymentMethodTranscription
>["transcription"];

const createServiceTypeOptions = ({
  clientConfigModel,
  transcription,
}: {
  clientConfigModel: ClientConfigModel;
  transcription: Transcription;
}) => {
  const registerSettings = clientConfigModel.getCorpIndividualCardsRegisterSettings();

  return [
    {
      status: registerSettings.services.bus,
      key: "enableToBus",
      label: transcription.services.bus,
    },
    {
      status: registerSettings.services.car,
      key: "enableToCar",
      label: transcription.services.car,
    },
    {
      status: registerSettings.services.flight,
      key: "enableToFlight",
      label: transcription.services.flight,
    },
    {
      status: registerSettings.services.hotel,
      key: "enableToHotel",
      label: transcription.services.hotel,
    },
    {
      status: registerSettings.services.ride,
      key: "enableToRide",
      label: transcription.services.ride,
    },
    {
      status: registerSettings.services.other,
      key: "enableToOther",
      label: transcription.services.other,
    },
  ] as const;
};

type Props = {
  back: () => void;
};

export const CreatePaymentMethod: React.FC<Props> = ({ back }) => {
  const queryClient = useQueryClient();

  const { createCard, isLoadingCreateCard, isLoadingEditCard } = useCards();
  const { clientConfig } = useClientConfig();

  const { transcription } = useCreatePaymentMethodTranscription();

  const form = useForm<CreatePaymentMethodSchema>({
    defaultValues: {
      allowApprovers: false,
      billingProfileToken: null,
      cvv: "",
      description: "",
      enableToBus: false,
      enableToCar: false,
      enableToClient: false,
      enableToFlight: false,
      enableToHotel: false,
      enableToOther: false,
      enableToRide: false,
      expirationDate: "",
      holderName: "",
      issuer: {},
      number: "",
    },
    onSubmit: async () => {
      const values = form.getValues();

      const sucess = await createCard({
        allowApprovers: values.allowApprovers,
        billingProfileToken: values.billingProfileToken,
        cvv: values.cvv,
        description: values.description,
        enableToBus: values.enableToBus,
        enableToCar: values.enableToCar,
        enableToClient: values.enableToClient,
        enableToFlight: values.enableToFlight,
        enableToHotel: values.enableToHotel,
        enableToOther: values.enableToOther,
        enableToRide: values.enableToRide,
        expirationDate: values.expirationDate,
        holderName: values.holderName,
        issuer: values.issuer,
        number: values.number,
      });

      if (!sucess) {
        return;
      }

      void invalidateBillingProfilePayableCardsForTravel(queryClient);

      back();
    },
    schema: createPaymentMethodSchema,
  });

  const { cvv, expirationDate, holderName, issuer, number } = form.watch([
    "cvv",
    "expirationDate",
    "holderName",
    "issuer",
    "number",
  ]);

  const getCreditCardMask = useCallback(
    ({ value }: { value: string }) => {
      let maskLength: number | null = 16;

      const formattedValue = removeBlankSpaces(value);
      const numberOfBlankSpaces = value.length - formattedValue.length;

      const maxLength = issuer ? Math.max.apply(null, issuer.lengths) : 16;

      if (formattedValue.length >= maxLength) {
        maskLength = maxLength;
      } else if (issuer) {
        maskLength =
          issuer.lengths.find((length: number) => {
            return formattedValue.length <= length;
          })! + numberOfBlankSpaces;
      }

      if (!maskLength || !(maskLength in CREDIT_CARD_MASKS)) {
        return false;
      }

      return CREDIT_CARD_MASKS[maskLength as keyof typeof CREDIT_CARD_MASKS];
    },
    [issuer],
  );

  const serviceTypeOptions = useMemo(() => {
    if (!clientConfig) {
      return [];
    }

    return createServiceTypeOptions({
      clientConfigModel: clientConfig,
      transcription: transcription,
    });
  }, [clientConfig, transcription]);

  const unavailableServiceTypeOptions = useMemo(() => {
    return serviceTypeOptions.filter(
      (option) => option.status === RegisterSettingsServiceStatus.DISABLED,
    );
  }, [serviceTypeOptions]);

  const requiredServiceTypeOptions = useMemo(() => {
    return serviceTypeOptions.filter(
      (option) => option.status === RegisterSettingsServiceStatus.REQUIRED,
    );
  }, [serviceTypeOptions]);

  const updateIssuer = useCallback(
    (cardNumber: string) => {
      const cleanedCardNumber = removeBlankSpaces(cardNumber);

      const issuer = CARD_ISSUERS.find(
        (item) => !!cleanedCardNumber.match(item.startsWith),
      );

      if (!issuer) {
        logger.error(`issuer not found: ${cleanedCardNumber}`);

        return;
      }

      form.setValue("issuer", issuer);
    },
    [form],
  );

  useEffect(() => {
    if (requiredServiceTypeOptions.length > 0) {
      for (const option of requiredServiceTypeOptions) {
        form.setValue(option.key, true);
      }
    }
  }, [requiredServiceTypeOptions]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.body.root}>
        <div css={styles.body.card.root}>
          <div css={styles.body.card.left}>
            <div css={styles.body.input.root}>
              <span css={styles.body.input.label}>
                {transcription.fields.cardHolderName.label}
              </span>
              <Form.Field
                name="holderName"
                render={({ onChange, value }) => (
                  <Input
                    css={styles.body.input.input}
                    onChange={onChange}
                    placeholder={
                      transcription.fields.cardHolderName.placeholder
                    }
                    value={value}
                  />
                )}
              />
              {form.errors["holderName"] ? (
                <InputErrorMessage>
                  {form.errors["holderName"].message}
                </InputErrorMessage>
              ) : (
                <span css={styles.body.input.info}>
                  {transcription.fields.cardHolderName.info}
                </span>
              )}
            </div>
            <div css={styles.body.input.root}>
              <span css={styles.body.input.label}>
                {transcription.fields.cardNumber.label}
              </span>
              <Form.Field
                name="number"
                render={({ onChange, value }) => (
                  <InputMasked
                    css={styles.body.input.input}
                    mask={getCreditCardMask({ value })}
                    onChange={(event) => {
                      onChange(event);
                      updateIssuer(event.target.value);
                    }}
                    placeholder={transcription.fields.cardNumber.placeholder}
                    value={value}
                  />
                )}
              />
              <InputErrorMessage>
                {form.errors["number"]?.message}
              </InputErrorMessage>
            </div>
          </div>
          <div css={styles.body.card.right.root}>
            <Cards
              acceptedCards={[
                "amex",
                "dinersclub",
                "elo",
                "mastercard",
                "visa",
              ]}
              expiry={expirationDate}
              cvc={cvv}
              locale={{
                valid: transcription.fields.cardExpirationDate.label,
              }}
              name={holderName}
              number={number}
              placeholders={{
                name: "Keanu Reeves",
              }}
            />
          </div>
        </div>
        <div css={styles.body.row}>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>
              {transcription.fields.cardExpirationDate.label}
            </span>
            <Form.Field
              name="expirationDate"
              render={({ onChange, value }) => (
                <InputMasked
                  css={styles.body.input.input}
                  maskType="month-and-year"
                  onChange={onChange}
                  placeholder={
                    transcription.fields.cardExpirationDate.placeholder
                  }
                  value={value}
                />
              )}
            />
            <InputErrorMessage>
              {form.errors["expirationDate"]?.message}
            </InputErrorMessage>
          </div>
          <div css={styles.body.input.root}>
            <span css={styles.body.input.label}>
              {transcription.fields.cardCvv.label}
            </span>
            <Form.Field
              name="cvv"
              render={({ onChange, value }) => (
                <InputMasked
                  css={styles.body.input.input}
                  maskType="cvv"
                  onChange={onChange}
                  placeholder={transcription.fields.cardCvv.placeholder}
                  value={value}
                />
              )}
            />
            <InputErrorMessage>{form.errors["cvv"]?.message}</InputErrorMessage>
          </div>
        </div>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>
            {transcription.fields.cardNickname.label}
          </span>
          <Form.Field
            name="description"
            render={({ onChange, value }) => (
              <Input
                css={styles.body.input.input}
                onChange={onChange}
                placeholder={transcription.fields.cardNickname.placeholder}
                value={value}
              />
            )}
          />
          <InputErrorMessage>
            {form.errors["description"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.body.services.root}>
          {serviceTypeOptions.map((option) => {
            const isDisabled =
              option.status === RegisterSettingsServiceStatus.DISABLED ||
              option.status === RegisterSettingsServiceStatus.REQUIRED;

            return (
              <label css={styles.body.services.checkbox} key={option.key}>
                <Checkbox
                  checked={form.watch(option.key)}
                  disabled={isDisabled}
                  onChange={(e) => {
                    form.setValue(option.key, e.target.checked);
                  }}
                  variant="pink"
                />
                <span css={styles.body.services.label({ isDisabled })}>
                  {option.label}
                </span>
              </label>
            );
          })}
        </div>
        {form.errors["at-least-one-service-enabled"] ? (
          <InputErrorMessage>
            {form.errors["at-least-one-service-enabled"].message}
          </InputErrorMessage>
        ) : null}
        {unavailableServiceTypeOptions.length > 0 ||
        requiredServiceTypeOptions.length > 0 ? (
          <span css={styles.body.services.info}>
            {transcription.unavailableServiceTypeOptionsInfo(
              unavailableServiceTypeOptions.map(
                (unavailableServiceTypeOption) =>
                  unavailableServiceTypeOption.label.toLowerCase(),
              ),
              requiredServiceTypeOptions.map((requiredServiceTypeOption) =>
                requiredServiceTypeOption.label.toLowerCase(),
              ),
            )}
          </span>
        ) : null}
        <label css={styles.body.services.checkbox}>
          <Checkbox
            checked={form.watch("allowApprovers")}
            onChange={(e) => {
              form.setValue("allowApprovers", e.target.checked);
            }}
            variant="pink"
          />
          <span
            css={styles.body.services.label({
              isDisabled: false,
            })}
          >
            {transcription.fields.allowApprovers}
          </span>
        </label>
      </div>
      <div css={styles.footer.root}>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          fill="outlined"
          onClick={() => {
            back();
          }}
          type="button"
        >
          {transcription.buttons.cancel}
        </Button>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          variant="pink"
        >
          {transcription.buttons.save}
        </Button>
      </div>
    </Form>
  );
};
