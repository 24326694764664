import { getAuthorizationHeader } from "../helpers/user.helper";
import { api } from "~/apps/shared/services/api";

export async function getEncryptionKey() {
  return api
    .request({
      url: "/booking/card/encryption-key",
      method: "GET",
      headers: getAuthorizationHeader()
    })
    .then(response => {
      return response.data;
    });
}

export function getGeneralEncryptionData() {
  return api
    .request({
      url: "/auth/encryption",
      method: "GET"
    })
    .then(response => {
      return response.data.data;
    });
}
