import React, { createContext, useCallback, useState } from "react";

import { ALERT_TYPES } from "~/apps/shared/constants";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";

import {
  CompleteLoyaltyProgram,
  SupplierLoyaltyProgram,
} from "../models/loyalty-program.model";
import { useApplication } from "./application.context";
import * as loyaltyProgramService from "./loyalty-program.service";
import { useUser } from "./user.context";

export type UserLoyaltyProgramFormData = {
  token?: string;
  number: string;
  supplierLoyaltyProgramToken: string;
};

interface Actions {
  fetchUserFidelityPrograms: () => Promise<void>;
  removeLoyaltyProgram: (program: CompleteLoyaltyProgram) => Promise<void>;
  saveLoyaltyProgram: (data: UserLoyaltyProgramFormData) => Promise<boolean>;
}

type State = {
  errorOnFetchRemoveLoyaltyProgram: Error | null;
  isLoading: boolean;
  isLoadingRemoveLoyaltyProgram: boolean;
  isLoadingSaveLoyaltyProgram: boolean;
  programs: CompleteLoyaltyProgram[];
  supplierLoyaltyPrograms: SupplierLoyaltyProgram[];
};

const initialState: State = {
  errorOnFetchRemoveLoyaltyProgram: null,
  isLoading: false,
  isLoadingRemoveLoyaltyProgram: false,
  isLoadingSaveLoyaltyProgram: false,
  programs: [],
  supplierLoyaltyPrograms: [],
};

type ContextProps = Actions & State;

const LoyaltyProgramsContext = createContext<ContextProps>({
  ...initialState,
  fetchUserFidelityPrograms: async () => {
    return;
  },
  removeLoyaltyProgram: async () => {
    return;
  },
  saveLoyaltyProgram: async () => {
    return false;
  },
});

export const LoyaltyProgramsProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const fetchUserFidelityPrograms = useCallback(async () => {
    if (!user) {
      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const {
      data: supplierLoyaltyPrograms,
      error: supplierLoyaltyProgramsError,
    } = await loyaltyProgramService.getSupplierLoyaltyPrograms();

    if (supplierLoyaltyProgramsError) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      showSnackMessage(
        supplierLoyaltyProgramsError.description,
        ALERT_TYPES.ERROR,
      );

      return;
    }

    const userLoyaltyProgramsResponse = await loyaltyProgramService.getUserLoyaltyPrograms(
      user.getUserToken(),
    );

    if (userLoyaltyProgramsResponse.error) {
      const error = userLoyaltyProgramsResponse.error;

      setState((prev) => ({
        ...prev,
        isLoading: false,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoading: false,
      programs: userLoyaltyProgramsResponse.data!,
      supplierLoyaltyPrograms: supplierLoyaltyPrograms || [],
    }));
  }, [showSnackMessage, user]);

  const removeLoyaltyProgram = useCallback(
    async (program: CompleteLoyaltyProgram) => {
      setState((prev) => ({
        ...prev,
        errorOnFetchRemoveLoyaltyProgram: null,
        isLoadingRemoveLoyaltyProgram: true,
      }));

      const inactiveLoyaltyProgramResponse = await loyaltyProgramService.inactiveLoyaltyProgram(
        program.token,
      );

      if (inactiveLoyaltyProgramResponse.error) {
        const error = inactiveLoyaltyProgramResponse.error;

        setState((prev) => ({
          ...prev,
          errorOnFetchRemoveLoyaltyProgram: error,
          isLoadingRemoveLoyaltyProgram: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      setState((prev) => ({
        ...prev,
        isLoadingRemoveLoyaltyProgram: false,
        programs: prev.programs.filter(
          (existingProgram) => existingProgram.token !== program.token,
        ),
      }));
    },
    [showSnackMessage],
  );

  const saveLoyaltyProgram = useCallback(
    async (data: UserLoyaltyProgramFormData) => {
      const { programs } = state;

      const loyaltyProgramAlreadyExists =
        !data.token &&
        programs.some(
          (loyaltyProgram) =>
            loyaltyProgram.supplierLoyaltyProgram.token ===
            data.supplierLoyaltyProgramToken,
        );

      if (loyaltyProgramAlreadyExists) {
        showSnackMessage(
          "Já existe um código cadastrado para esse programa.",
          ALERT_TYPES.ERROR,
        );

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingSaveLoyaltyProgram: true,
      }));

      const saveLoyaltyProgramResponse = !data.token
        ? await loyaltyProgramService.createLoyaltyProgram(data)
        : await loyaltyProgramService.editLoyaltyProgram(data, data.token);

      if (saveLoyaltyProgramResponse.error) {
        const error = saveLoyaltyProgramResponse.error;

        setState((prev) => ({
          ...prev,
          isLoadingSaveLoyaltyProgram: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      if (!saveLoyaltyProgramResponse.data) {
        const error = ERROR.UNEXPECTED;

        setState((prev) => ({
          ...prev,
          isLoadingSaveLoyaltyProgram: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      const savedLoyaltyProgram = saveLoyaltyProgramResponse.data!;
      const updatedPrograms = data.token
        ? programs.map((program) =>
            program.token === data.token ? savedLoyaltyProgram : program,
          )
        : [...programs, savedLoyaltyProgram];

      setState((prev) => ({
        ...prev,
        isLoadingSaveLoyaltyProgram: false,
        programs: updatedPrograms,
      }));

      return true;
    },
    [showSnackMessage, state.programs],
  );

  return (
    <LoyaltyProgramsContext.Provider
      value={{
        ...state,
        fetchUserFidelityPrograms,
        removeLoyaltyProgram,
        saveLoyaltyProgram,
      }}
    >
      {children}
    </LoyaltyProgramsContext.Provider>
  );
};

export const useLoyaltyPrograms = useContextFactory(
  "LoyaltyProgramsContext",
  LoyaltyProgramsContext,
);
