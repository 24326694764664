import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";

import {
  createGenerateClassName,
  StylesProvider,
  jssPreset,
} from "@material-ui/styles";
import { LocationProvider } from "@reach/router";
import { ThemeProvider } from "emotion-theming";
import { create } from "jss";
import moment from "moment-timezone/builds/moment-timezone-with-data";

import { theme } from "@skin";

import { Routes } from "./app-routes";
import { ApplicationProvider } from "./apps/corporate/contexts/application.context";
import { PollingProvider } from "./apps/corporate/contexts/polling.context";
import { ErrorBoundary } from "./apps/shared/components/error-boundary/error-boundary";
import { IOSRootComponents } from "./apps/shared/components/ios-root-components/ios-root-components";
import { PageViewsTracker } from "./apps/shared/components/page-views-tracker/page-views-tracker";
import { RefreshDialog } from "./apps/shared/components/refresh-dialog/refresh-dialog";
import { Snackbar } from "./apps/shared/components/snackbar/snackbar";
import { withClearCache } from "./apps/shared/hooks/with-clear-cache";
import store from "./store.js";

import "./assets/styles/generics/general-icons.css";
import "./assets/styles/generics/st-fonts.css";
import "~/apps/shared/styles/index.scss";
import "moment/dist/locale/pt-br";
import "react-credit-cards/es/styles-compiled.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-virtualized/styles.css";
import "simplebar/dist/simplebar.min.css";

moment.tz.setDefault("America/Sao_Paulo");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = withClearCache(() => {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider
        generateClassName={createGenerateClassName()}
        jss={create({
          ...jssPreset(),
          insertionPoint: "jss-insertion-point",
        })}
      >
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <ErrorBoundary level="fatal">
              <ApplicationProvider>
                <PollingProvider>
                  <IOSRootComponents />
                  <LocationProvider>
                    <PageViewsTracker />
                    <Routes />
                  </LocationProvider>
                  <RefreshDialog />
                  <Snackbar />
                </PollingProvider>
              </ApplicationProvider>
            </ErrorBoundary>
          </Provider>
        </QueryClientProvider>
      </StylesProvider>
    </ThemeProvider>
  );
});
