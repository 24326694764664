import { AxiosError, AxiosRequestConfig } from "axios";

export class ApiRejectedInterceptorHelper {
  private readonly accessTokenLocalStorageKey = "access_token";
  private readonly refreshAccessTokenTimedOutMessage = "ECONNABORTED";

  public attachTokenToRequest(
    request: AxiosRequestConfig,
    token: string,
  ): void {
    request.headers["Authorization"] = token;
  }

  public clearAccessToken(): void {
    localStorage.removeItem(this.accessTokenLocalStorageKey);
  }

  public hasRefreshAccessTokenTimedOut(error: AxiosError): boolean {
    return error.code === this.refreshAccessTokenTimedOutMessage;
  }

  public hasRefreshAccessTokenThrownMismatch(error: AxiosError): boolean {
    if (error.response) {
      const { data } = error.response;

      return [
        "Client does not match stored session data.",
        "Refresh token does not match stored session data.",
        "User does not match stored session data.",
      ].some((message) => data?.message === message);
    }

    return false;
  }

  public isForbidden(error: AxiosError): boolean {
    if (!error.response) {
      return false;
    }

    const { status } = error.response;

    return status === 403;
  }

  public isInternalServerError(error: AxiosError): boolean {
    if (!error.response) {
      return false;
    }

    const { status } = error.response;

    return status === 500;
  }

  public isRefreshAccessTokenDuplicate(error: AxiosError): boolean {
    if (!error.response) {
      return false;
    }

    const { data } = error.response;

    return data?.message === "User has recently refreshed this session.";
  }

  public isUnauthorized(error: AxiosError): boolean {
    if (!error.response) {
      return false;
    }

    const { status } = error.response;

    return status === 401;
  }

  public setAccessToken(accessToken: string): void {
    localStorage.setItem(this.accessTokenLocalStorageKey, accessToken);
  }
}
