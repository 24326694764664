import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    analytics: {
      label: "Analytics",
      links: {
        dataAnalytics: "Data analytics",
        financialControl: "Financial control",
        realTimeTravels: "Real-time travels",
        reportDownload: "Report download",
      },
    },
    configurations: {
      label: "Configurations",
      links: {
        accountSettings: "Account settings",
        companyOrganization: "Company structure",
        expenseRules: "Expense rules",
        featureControl: "Feature control",
        integrations: "Integrations",
        people: "People",
        tripRules: "Trip rules",
      },
    },
    events: {
      label: "Events",
      links: {
        nextEvents: "Next events",
        ongoingEvents: "Ongoing events",
        pastEvents: "Past events",
      },
    },
    expenses: {
      label: "Expenses",
      links: {
        advances: "My advances",
        expenses: "My expenses",
        others: "Others reports",
        reports: "My reports",
      },
    },
    title: "Others",
  },
  [LanguageCode.PT_BR]: {
    analytics: {
      label: "Dashboard de Analytics",
      links: {
        dataAnalytics: "Análise de dados",
        financialControl: "Controle financeiro",
        realTimeTravels: "Viagens em tempo real",
        reportDownload: "Download de relatórios",
      },
    },
    configurations: {
      label: "Configurações",
      links: {
        accountSettings: "Configurações da conta",
        companyOrganization: "Organização da empresa",
        expenseRules: "Regras de despesas",
        featureControl: "Controle de funcionalidades",
        integrations: "Integrações",
        people: "Pessoas",
        tripRules: "Regras de viagem",
      },
    },
    events: {
      label: "Eventos",
      links: {
        nextEvents: "Próximos eventos",
        ongoingEvents: "Eventos em progresso",
        pastEvents: "Eventos passados",
      },
    },
    expenses: {
      label: "Despesas",
      links: {
        advances: "Meus adiantamentos",
        expenses: "Despesas",
        others: "Relatórios de outros",
        reports: "Meus relatórios",
      },
    },
    title: "Outros",
  },
} as const;

export const useNavBarDesktopOthersMenuTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
