import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    title: "Error initializing chat",
    description:
      "An error occurred while trying to download the chat script. This may have occurred for various reasons, such as:",
    bulletPoints: [
      "Firewall or proxy settings blocking access.",
      "Internet connection issues.",
      "Content Security Policy (CSP) - Restrictions that limit the loading of external resources.",
      "Strict privacy settings - Blocking of third-party cookies or tracking.",
      "Ad/script blockers - Extensions that block third-party scripts.",
      "Browser incompatibility - Outdated or very old browsers.",
      "Sometimes this problem is solved with a Ctrl+F5 on the page.",
    ],
    pleaseContactUs:
      "If the chat is not available, please contact us by email:",
    okButton: "Ok",
  },
  [LanguageCode.PT_BR]: {
    title: "Erro na inicialização do chat",
    description:
      "Ocorreu um erro ao tentar baixar o script do chat. Isso pode ter ocorrido por diversos motivos, como:",
    bulletPoints: [
      "Configurações de firewall ou proxy bloqueando o acesso.",
      "Problemas de conexão com a internet.",
      "Content Security Policy (CSP) - Restrições que limitam o carregamento de recursos externos.",
      "Configurações de privacidade rigorosas - Bloqueio de cookies de terceiros ou rastreamento.",
      "Bloqueadores de anúncios/scripts - Extensões que bloqueiam scripts de terceiros.",
      "Incompatibilidade de navegador - Navegadores desatualizados ou muito antigos.",
      "Algumas vezes esse problema é resolvido com um Ctrl+F5 na página.",
    ],
    pleaseContactUs:
      "Na ausência do chat, por favor entre em contato pelo email:",
    okButton: "Ok, entendi",
  },
};

export const useLiveChatDialogErrorTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription = transcriptions[languageCode];

  return { transcription };
};
